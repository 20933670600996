.global-search-block{
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
    width: 100%;
}
.global-search-block h3.global-title{
    color: #3c6bd5;
    font-weight: 500;
    text-transform: capitalize;
}
.global-search-block .company-search-data {
    background: #ffffff;
}
.global-search-block .company-search-data .data-item{
    padding: 15px 25px;
}
.global-search-block .company-search-data .data-item{  
    border-top: 1px solid #ececec;
}
.global-search-block .company-search-data .data-title{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    margin: 10px 0;
    line-height: 1;
}
.global-search-block .company-search-data .company ul{
    display: flex;
    flex-wrap: wrap;
}
.global-search-block .company-search-data .company ul li:nth-child(odd){
    display: inline-block;
    width: 50%;
    padding-right: 30px;
}
.global-search-block .company-search-data .company ul li:nth-child(even){
    display: inline-block;
    width: 50%;
    padding-left: 30px;
}
.global-search-block .company-search-data .company ul li div{
    padding: 10px 5px;
    width: 100%;
}
.global-search-block .company-search-data .company ul li div:hover{
    background-color: #ececec;
    cursor: pointer;
}
.global-search-block .company-search-data .company ul li div span.symbol{
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1;
    width: 75px;
}
.global-search-block .company-search-data .company ul li div span.name{
    text-transform: capitalize;
    font-weight: 400;
    color: var(--primary-text-dark);
    font-size: 14px;
    line-height: 1;
    width: calc(100% - 225px);
    margin-left: 10px;
}
.global-search-block .company-search-data .company ul li div span.sector{
    text-transform: uppercase;
    font-weight: 400;
    color: var(--primary-text-dark);
    font-size: 12px;
    line-height: 1;
    width: 150px;
    text-align: right;
    padding-left: 5px;
}
.global-search-block .company-search-data .data-item.global ul{
    display: flex;
    flex-wrap: wrap;
}
.global-search-block .company-search-data .data-item.global ul li{
    display: inline-block;
    padding: 10px 0;
    cursor: pointer;
}
.global-search-block .company-search-data .data-item.global ul li:nth-child(odd){
    display: inline-block;
    width: 50%;
    padding-right: 30px;
}
.global-search-block .company-search-data .data-item.global ul li:nth-child(even){
    display: inline-block;
    width: 50%;
    padding-left: 30px;
}
.global-search-block .company-search-data .data-item.global ul li:hover{
    background-color: #ececec;
}
.global-search-block .company-search-data .data-item.global ul li .global-icon{
    display: inline-block;
    line-height: 1;
    margin: 0 15px 0 5px;
}
.global-search-block .company-search-data .data-item.global ul li .global-icon img{
    width: 12px;
}
.global-search-block .company-search-data .data-item.global ul li .global-data .global-name{
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1;
    width: 100%;
    margin-bottom: 5px;
}
.global-search-block .company-search-data .data-item.global ul li .global-meta{
    font-size: 12px;
    color: var(--primary-text-dark);
    margin: 0;
    line-height: 1;
}