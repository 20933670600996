.project-main{
    flex-direction: column;
}
.project-main .add-project-filter{
    padding: 10px 10px 0;
    justify-content: flex-end;
}
.project-main .add-project-filter button.add-project{
    display: inline-block;
    height: 40px;
    background-color: rgba(57,109,213,.75);
}
.add-project-modal form .btn-primary{
    background-color: rgba(57,109,213,.75);
}
.add-project-modal form .btn-cancel{
    background: transparent;
}
.edit-project-modal form .btn-primary{
    background-color: rgba(57,109,213,.75);
}
.edit-project-modal form .btn-cancel{
    background: transparent;
}
.project-table-sec{
    position: relative;
    margin: 10px;
    padding: 45px 5px 0px;
    overflow: hidden;
    border: 0.25px solid var(--light-border-color)!important;
    border-radius: 4px;
    width: calc(100% - 20px);
}
.project-table-sec .title{
    margin: 0;
    border-bottom: 0.25px solid var(--light-border-color)!important;
    position: absolute;
    top: 0;
    padding: 12px;
    left: 0;
    right: 0;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
}
.project-table-sec .table thead th{
    border-top: 0px!important;
    border-bottom: 2px solid var(--light-border-color)!important;
    font-weight: 400;
}
.project-table-sec table tbody tr td{
    border-top: 0px!important;
    border-bottom: 0.25px solid var(--light-border-color)!important;
}
.project-table-sec table tr td{
    vertical-align: middle;
    font-size: 13px;
    color: #0d0d0d;
    padding: 5px 10px;
}
.project-table-sec table tr td .toggle-switch{
    justify-content: center;
    min-width: 150px;

}
.project-table-sec input{
    cursor: pointer;
}
.project-table-sec table tr td button{
    border-radius: 4px;
    cursor: pointer;
    margin: 3px;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.project-table-sec table tr td button.action{
    width: 25px;
    height: 25px;
    border: 0.25px solid var(--light-border-color);
    color: #356299;
    background: transparent;
    position: relative;
    z-index: 99;
}
.project-table-sec table tr td button.action:before{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: '';
    background: transparent;
    z-index: 9;
}
.project-table-sec .view-role{
    color: var(--primary-grey)!important;
}
.add-project-modal input::placeholder{
    text-align: left;
}
.add-project-modal input , .edit-project-modal input{
    border: 0.25px solid var(--light-border-color);
}
.btn-cancel{
    border: 0.25px solid var(--light-border-color);
}
/* .project-table-sec table tr td button.action:hover:before{
    background: #356299;
    opacity: 0.5;
} */
.project-table-sec table tr td button.action:hover:before svg path{
    fill: #ffffff;
}
.project-table-sec table tr td button.action svg{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translate(6px, 6px);
    z-index: 0;
}
.project-table-sec table tr td button svg path{
    fill: #356299;
}
.modal-dialog .modal-header .modal-title{
    font-size: 20px;
    font-weight: 600;
}
.view-role-modal .role-block{
    width: 225px;
    margin: 10px;
}
.view-role-modal .role-block .role-title{
    margin: 0;
    border: 0.25px solid var(--light-border-color);
    font-size: 20px;
    font-weight: 500;
    padding: 5px 10px
}
.view-role-modal .role-block ul{
    padding-left: 10px;
}
.view-role-modal .role-block ul li span{
    font-size: 12px;
}
