form{
    position: relative;
}
.login-sec.old {
    min-height: 100vh;
    display: flex;
    align-items: center;
    background: #ffffff!important;
    position: relative;
}
.login-sec.old:before{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background: url('../../../assets//media/bgTree.png'); */
    background: url('../../assets/media/bgTree.svg');
    background-size: cover;
    opacity: 0.75;
}
.login-sec.old .form-side{
    position: relative;
    min-height: 100vh;
}
.login-sec.old .form-side::before{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 15px;
    background: #ffffff;
    box-shadow: 0 0 10px #bababa;
    border-radius: 0 55px 55px 0;
    z-index: 0;
}
.login-sec.old .logo{
    position: absolute;
    top: 50px;
}
/* .login-sec.old .img-side{
    background-color: #e5e5e573;
} */
.login-sec.old .login-title{
    text-align: center;
    font-size: 30px;
    color: var(--primary-text-normal);
    margin-bottom: 100px;
}
.login-sec.old .login-title img{
    width: 150px;
    height: auto;
}
.login-sec.old .login-img{
    text-align: right;
    /* margin-right: 50px; */
}
.login-sec.old .login-img img{
    max-width: 550px;
    /* max-width: 350px; */
    margin: 0 auto;
}
.login-sec.old .login-title span{
    font-weight: 600;
    color: #3969d5;
}
.login-sec.old .form-area{
    /* max-width: 300px;
    width: 300px; */
    text-align: center;
}
.login-sec.old .form-area .text-block{
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 50px;
}
.login-sec.old .form-area .text-block h3{
    /* font-size: 30px; */
    font-size: 24px;
    font-weight: 600;
    text-transform: initial;
    color: var(--primary-text-normal);
}
.login-sec.old .form-area .text-block p{
    color: #B1B1B1;
    font-size: 15px;
    margin: 7px auto;
}
.login-sec.old .form-area form{
    max-width: 300px;
    width: 300px;
    margin: 0 auto;
}
.login-sec.old .form-area form .form-group{
    margin-bottom: 25px;
    width: 100% !important;
    position: relative;
}
.login-sec.old .form-area form .form-group span.icon{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-sec.old .form-area form .form-group span.eye-icon{
    bottom: 0;
    top: 0;
    position: absolute;
    right: 7px;
    width: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.login-sec.old .form-area form .form-group span.eye-icon img{
    width: 100%;
}
.login-sec.old .form-area form .form-group .form-control{
    border: 1px solid rgba(57, 105, 213, 0.5);
    padding-left: 30px;
    font-size: 12px;
    min-height: 38px;
    color: var(--primary-text-normal)!important;
}
.login-sec.old .form-area form .form-group .form-control::placeholder{
    color: #00000050;
    font-size: 12px;
}
.login-sec.old .form-area form .form-group .error-box{
    font-size: 12px;
    position: absolute;
}
.login-sec.old .form-area form button{
    color: #ffffff;
    width: 100%;
    /* width: calc(100% + 30px); */
    background: #3969d5;
    /* margin-left: -15px; */
}
.login-sec.old .form-area form label{
    font-size: 14px;
    line-height: 1;
    margin: 0;
}
.field-error{
    color: red;
    position: absolute;
    font-size: 12px;
}
/* .field-errors{
    color: red;
    position: absolute;
    font-size: 14px;
    margin-top: -17px;
} */
form .forgot-password{
    /* margin: 0;
    position: absolute;
    line-height: 1;
    top: -20px;
    right: 0px;
    font-size: 12px;
    cursor: pointer; */
    line-height: 1;
    color: #B0B0B0;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 50px;
}

.forgot-modal .modal-header{
    border: none;
}
.forgot-modal .modal-body{
    padding: 50px;
}
.forgot-modal .modal-body .forgot-content{
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px dashed #4040402e;
}     
.forgot-modal .modal-body .forgot-content h3{
    font-size: 36px;
    color: #3969d5;
    margin: 10px auto;
}
.forgot-modal .modal-body .forgot-content p{
    font-size: 12px;
    font-weight: 400; 
    color: #959595;
    margin: 0;
}
.forgot-modal .form-group label{
    font-size: 12px;
    font-weight: 400; 
    color: #404040;
    margin: 0;
}
.forgot-modal .form-group input{
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
    text-align-last: left;
}
.forgot-modal .form-group .field-error{
    position: absolute;
    color: #ff0000;
    font-size: 12px;
}
.forgot-modal .close-subs-view{
    position: absolute;
    right: 15px;
    width: 25px;
    height: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: none;
    background: #3969d5;
    color: #fff;
    border-radius: 50%;
    box-shadow: 2px 0 6px #323030;
    z-index: 99;
}
.forgot-modal .close-subs-view svg{
    line-height: 1;
    width: 15px;
    height: 15px;
}
.forgot-modal .forgot-submit-btn{
    display: inline-flex;
    width: 100%;
    margin: 15px auto;
}
.forgot-modal .forgot-submit-btn button.btn-primary{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 400;
    border: 0.25px solid #3969d5;
    padding: 7px 5px;
    background: #3969d5;
    color: #ffffff;
}