.company-search-block{
    margin-bottom: 15px;
    position: relative;
}
.company-search-block input{
    border: 0.25px solid var(--light-border-color);
}
.company-search-block input:focus{
    box-shadow: none;
}
.company-search-block .company-search-data {
    position: absolute;
    top: 35px;
    left: 0;
    right: 15px;
    background: #ffffff;
    z-index: 9999;
    border-radius: 0 0 10px 10px;
    border: 1px solid var(--sharp-border-color);
    box-shadow: 5px 10px 15px rgb(0, 0, 0, 0.2);
}
.company-search-block .company-search-data .data-item{
    padding: 15px 25px;
}
.company-search-block .company-search-data .data-item:not(:last-child){
    border-bottom: 1px solid #ececec;
}
.company-search-block .company-search-data .data-title{
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    margin: 10px 0;
    line-height: 1;
}
.company-search-block .company-search-data .company ul li div{
    padding: 10px 5px;
    width: 100%;
}
.company-search-block .company-search-data .company ul li div:hover{
    background-color: #ececec;
    cursor: pointer;
}
.company-search-block .company-search-data .company ul li div span.symbol{
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1;
    width: 50px;
}
.company-search-block .company-search-data .company ul li div span.name{
    text-transform: capitalize;
    font-weight: 400;
    color: var(--primary-text-dark);
    font-size: 14px;
    line-height: 1;
    width: calc(100% - 200px);
}
.company-search-block .company-search-data .company ul li div span.sector{
    text-transform: uppercase;
    font-weight: 400;
    color: var(--primary-text-dark);
    font-size: 12px;
    line-height: 1;
    width: 150px;
    text-align: right;
    padding-left: 5px;
}
.company-search-block .company-search-data .data-item.global ul li{
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    cursor: pointer;
}
.company-search-block .company-search-data .data-item.global ul li:hover{
    background-color: #ececec;
}
.company-search-block .company-search-data .data-item.global ul li .global-icon{
    display: inline-block;
    line-height: 1;
    margin: 0 15px 0 5px;
}
.company-search-block .company-search-data .data-item.global ul li .global-icon img{
    width: 12px;
}
.company-search-block .company-search-data .data-item.global ul li .global-data .global-name{
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1;
    width: 100%;
    margin-bottom: 5px;
}
.company-search-block .company-search-data .data-item.global ul li .global-meta{
    font-size: 12px;
    color: var(--primary-text-dark);
    margin: 0;
    line-height: 1;
}
.company-search-block .company-search-data .view-more-btn{
    margin: 0;
    text-align: center;
}
.company-search-block .company-search-data .view-more-btn .btn{
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
    box-shadow: none;
    padding: 0;
}