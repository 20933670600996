.contact-list-sec {
    width: 100%;
}

.contact-list-sec .project-main .add-project-filter .add-project {
    background-color: var(--primary-color);
}

.contact-list-sec .brain-table-card .sc-dkPtRN.hCBnvI.rdt_TableHeadRow .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol ,
.contact-list-sec .brain-table-card .sc-jrQzAO.fhCFWO.rdt_TableRow .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell{
    text-align: left;
    justify-content: flex-start !important;
}

/* .contact-list-sec .brain-table-card .sc-dkPtRN.hCBnvI.rdt_TableHeadRow .rdt_TableCol:first-child > div, .contact-list-sec .brain-table-card .sc-jrQzAO.fhCFWO.rdt_TableRow .rdt_TableCell:first-child > div{
    max-width: 50px;
} */