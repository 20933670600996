.dashboard .dashboard-content::-webkit-scrollbar-track{
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: #F5F5F5;
}

.dashboard .dashboard-content::-webkit-scrollbar{
    width: 6px;
    height: 4px;
    background-color: #3969d5;
}

.dashboard .dashboard-content::-webkit-scrollbar-thumb{
    background-color: #3969d5;
}
/* .dashboard{
    overflow: auto;
} */
.dashboard .dashboard-content{
    padding: 0px 10px 10px 60px;
    height: calc(100vh - 60px);
    overflow: auto;
}

.seperator{
    margin: 100px auto;
    width: 100%;
}