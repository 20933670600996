.inline-permission-list {
  display: flex;
  min-width: 300px;
  flex-wrap: wrap;
  justify-content: center;
}

.inline-permission-list .inline-permission-list-item {
  /* width: 65px; */
  height: 30px;
  background: #eeeeee;
  margin: 5px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  border-radius: 4px;
  color: var(--primary-text-normal);
  /* min-width: 65px; */
  display: flex;
  justify-content: center;
  min-width: 90px;
  max-width: 90%;
  /* margin: 20px auto; */
  margin-bottom: 0;
  padding: 12px;
}
