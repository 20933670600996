.user-profile-main{
    width: 100%;
    padding: 15px 30px;
}
.user-profile-main .title{
    border: var(--light-border);
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;
}
.user-profile-main .title h3{
    font-size: 16px;
    font-weight: 700;
    color: var(--primary-grey);
    position: relative;
    padding: 10px;
    margin: 0;
}
.user-profile-main .user-img-main{
    display: inline-flex;
    width: 100%;
    margin: 30px auto;
    justify-content: center;
    align-items: center;
}
.user-profile-main .user-img-main .image-form {
    position: relative;
}
.user-profile-main .user-img-main .image-form label{
    position: relative;
    margin: 30px auto;
}
.user-profile-main .user-img-main .image-form label img{
    max-width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
}
.user-profile-main .user-img-main .image-form label .edit-icon{
    position: absolute;
    background: #3969D5;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    bottom: 15px;
    right: 0;
    box-shadow: 0 0 10px #fff;
}
.user-profile-main .user-img-main .image-form label .edit-icon svg{
    width: 10px;
    height: 10px;
}
.user-profile-main .user-img-main .image-form label .edit-icon input{
    width: 20px;
    height: 20px;
    opacity: 0;
    position: absolute;
    cursor: pointer;
    z-index: 99;
}
.user-profile-main .user-img-main .image-form button{
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #3969D5;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
}
.user-profile-main .user-img-main .image-form small{
    font-size: 12px;
    font-weight: 400;
    color: #3d3d3d;
    margin: 15px auto;
    display: inline-block;
}
.user-form-main form label , .user-form-main form input{
    font-size: 12px;
    color: #3d3d3d;
}
.user-form-main form .user-personal-block , .user-form-main form .user-company-block{
    padding: 30px;
    border: var(--light-border);
    border-radius: 5px;
    margin-bottom: 30px;
}
.user-form-main form .user-personal-block .title , .user-form-main form .user-company-block .title{
    margin-bottom: 15px;
    border: none;
}
.user-form-main form .user-personal-block .personal-info , .user-form-main form .user-company-block .company-info{
    display: flex;
    flex-wrap: wrap;
}
.user-form-main form .btn-primary{
    background: #3969D5;
    min-width: 100px;
}