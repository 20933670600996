.navbar {
    background-color: #ffffff !important;
    /* box-shadow: 0 0 10px #ececec; */
    height: 60px;
    border-bottom: 1px solid var(--sharp-border-color) !important;
}
.navbar .navbar-brand img {
    max-width: 100px;
    width: 100px;
}
.navbar .direct-dashboard .btn-primary{
    background-color: #3969d5;
}
.navbar .user-option {
    position: relative;
    overflow: hidden;
    width: auto;
}
.navbar .user-option.active {
    overflow: initial;
}
.navbar .user-option .user-img {
    margin: 0;
    position: relative;
    z-index: 9999997;
    /* background: #ffffff; */
}
.navbar .user-option .user-img img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
}
.navbar .user-option .user-img span.user-trigger {
    cursor: pointer;
}
.navbar .user-option ul {
    /* padding: 5px 0; */
    background: #ffffff;
    box-shadow: 0 0 10px #cecece;
    border-radius: 7px;
    position: absolute;
    min-width: 150px;
    right: 0;
    opacity: 0;
    top: -40px;
    transition: all 0.5s;
    z-index: 9999996;
}
.navbar .user-option.active ul {
    top: 40px;
    opacity: 1;
    transition: all 0.5s;
}
.navbar .user-option ul li.option-item {
    line-height: 1;
    padding: 6px 10px;
    box-shadow: 0 0 1px #ececec;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    margin: 0;
}
.navbar .user-option ul li.option-item:hover {
    background: none;
}
.navbar .user-option ul li.option-item:last-child {
    border: none;
}
.navbar .user-option ul li.option-item:not(:first-child):after {
    position: absolute;
    content: "";
    /* background: rgb(153 167 206); */
    /* background: rgb(247 247 247); */
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s;
}
.navbar .user-option ul li.option-item p {
    line-height: 1;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    z-index: 99;
}
.navbar .user-option ul li.option-item:not(:first-child) p {
    cursor: pointer;
}
.navbar .user-option ul li.option-item:not(:first-child):hover:after {
    left: 0;
    background: rgb(247 247 247);
}
.navbar .user-option ul li.option-item:not(:first-child):hover p {
    /* color: #ffffff; */
    /* color: #; */
    transition: 0.6s all;
}
.navbar .user-option ul li.option-item p span.icon {
    margin-right: 5px;
    /* border-radius: 50%; */
    background: transparent;
    display: inline-block;
    /* box-shadow: 0 0 10px #ececec; */
}
.navbar .user-option ul li.option-item p span.icon svg {
    width: 15px;
    height: 15px;
}
.navbar
    .user-option
    ul
    li.option-item:not(:first-child):hover
    p
    span.icon
    svg
    path {
    /* fill: #ffffff; */
    transition: 0.6s all;
}

.navbar .user-option ul li.option-item{
    line-height: 1;
    padding: 6px 10px;
    box-shadow: 0 0 1px #ececec;
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    margin: 0;
}
.navbar .user-option li svg{
    position: absolute;
    top: 0px;
    left: 5px;
    z-index: 0;
    width: 15px;
    height: 15px;
}
.navbar .user-option ul li.option-item:hover{
    background: none;
}
.navbar .user-option ul li.option-item:last-child{
    border: none;
}
.navbar .user-option ul li.option-item:not(:first-child):after{
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.5s;
}
.navbar .user-option ul li.option-item p{
    line-height: 1;
    font-size: 13px;
    font-weight: 600;
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.navbar .user-option ul li.option-item:not(:first-child) p{
    cursor: pointer;
}
.navbar .user-option ul li.option-item:not(:first-child):hover:after{
    left: 0;
    background: rgb(247 247 247);
}
.navbar .user-option ul li.option-item:not(:first-child):hover p{
    transition: 0.6s all;
}
.navbar .user-option ul li.option-item p span.icon{
    width: 20px;
    height: 15px;
    margin-right: 5px;
    background: transparent;
    display: inline-block;
}
.navbar .user-option ul li.option-item p span.icon svg{
    width: 15px;
    height: 15px;
}

@media screen and (max-width: 767px) {
    .navbar .navbar-brand{
        margin-right: 0px;
    }
}
