.project-links-sec{
    width: calc(65% - 30px);
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 5px;
}
.project-links-sec > .d-flex{
    padding: 0 40px;
}
.project-links-sec .project-items{
    position: relative;
    width: 170px;
    height: 140px;
    text-align: center;
    padding: 10px;
    margin: 5px;
    
    border-radius: 4px;
    border: 0.25px solid var(--light-border-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: 0.7s all;
    transform: scale(1);
    overflow: hidden;
   
}
/* .project-links-sec .project-items:before{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.7s all;
    background: linear-gradient(0deg , #6483a1b0, #ececec);
} */
.project-links-sec .project-items:hover{
    transform: scale(1.05);
    transition: 0.3s all;
}
/* .project-links-sec .project-items:hover:before{
    transition: 0.7s all;
    top: 0;
    background: #adadad;
} */
.project-links-sec .project-items a{
    position: relative;
    z-index: 99;
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #3d3d3d;
    text-decoration: none;
    transition: 0.3s all;
}
/* .project-links-sec .project-items:hover a{
    color: #ffffff;
    transition: 0.7s all;
} */
.project-links-sec .project-items a img{
    width: 40px;
    height: 40px;
}
.project-links-sec .project-items a svg path{
    /* fill: rgb(4, 40, 147);
    fill: #0081f2; */
    fill: #305e85;
}
.project-links-sec .project-items h3{
    font-size: 15px;
    margin: 10px auto 0;
    /* color: rgb(4, 40, 147);
    color: #0081f2; */
    color: #a6a6a6;
    margin-top: 20px;
    font-weight: 400;
}
/* .project-links-sec .project-items:hover a svg path{
    fill: #ffffff
}
.project-links-sec .project-items:hover a h3{
    fill: #ffffff
} */

@media screen and (max-width: 991px){
    .project-links-sec{
        max-width: 100%;
        width: 100%;
    }
    .project-links-sec > .d-flex{
        padding: 0 15px;
    }
}

