.view-roles-modal.custom-modal-width {
  width: 80%;
}

.custom-modal-width {
  width: 80%;
}

.view-roles-modal-body .permission-container {
}

.view-roles-modal-body .permission-heading {
  border: 1px solid #0000ff29;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px 8px;
}

.view-roles-modal-body .permission-heading h6 {
  padding: 0;
  margin: 0;
  color: var(--primary-text-normal);
}

.view-roles-modal-body .permissions {
}

.view-roles-modal-body .permissions ul {
  font-size: 14px;
  list-style: none;
  font-weight: 100;
}

.view-roles-modal-body .permissions ul li {
  padding: 2px 0px;
  color: #000000db;
}

.view-roles-modal-body .permissions ul li div span{
  color: var(--primary-text-normal);
}
