.not-found-background {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-background .not-found-inner {
  background: #ffffff54;
  border-radius: 12px;
  padding: 15px;
  min-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.not-found-background .not-found-inner img{
  max-width: 500px;
}

.not-found-background .not-found-inner h1 {
  font-weight: 600;
  color: var(--primary-color);
  font-size: 38px;
  margin-bottom: 50px;
}

.not-found-background .not-found-inner h5 {
  color: var(--primary-grey);
  font-size: 28px;
  font-weight: 400;
}

.not-found-background .not-found-inner p {
  font-size: 13px;
  color: var(--primary-grey);
}
.not-found-background .not-found-inner a{
  background-color: var(--primary-color);
  width: 240px;
  height: 55px;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
