.assign-user-modal form{
    max-height: 550px;
    overflow-y: auto;
}
.assign-user-modal .form-group.company-select .css-b62m3t-container .css-1s2u09g-control{
    height: auto;
}
.assign-user-modal .form-group.company-select .css-b62m3t-container .css-1s2u09g-control .css-g1d714-ValueContainer > div > div{
    font-size: 11.5px;
}
.assign-user-modal .form-group.company-select .css-b62m3t-container .css-1s2u09g-control .css-g1d714-ValueContainer > div svg{
    width: 10px;
    height: 10px;
}
.assign-user-modal .add-more-btn{
    padding-top: 10px;
}
.assign-user-modal .add-more-btn button{
    color: var(--primary-color);
    border: none;
    background: none;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
    margin-top: -15px;
}
.assign-user-modal label{
    position: relative;
    width: 100%;
    color: var(--primary-text-normal);
    line-height: 1;
}
.assign-user-modal .assign-form-block{
    max-height: 550px;
    overflow: auto;
}
.assign-user-modal .multi-assign-block .single-assign-block{
    position: relative;
    /* border-right: 1px solid #ececec; */
    display: inline-block;
    width: 100%;
}
.assign-user-modal .multi-assign-block .single-assign-block:not(:first-child){
    border-top: var(--light-border);
    padding-top: 15px;
    margin: 10px auto 0;
}
.assign-user-modal .multi-assign-block .remove-instance{
    position: absolute;
    top: 5px;
    right: 0px;
}
/* .assign-user-modal .multi-assign-block .single-assign-block:not(:first-child) .remove-instance{
    top: 90px;
} */
.assign-user-modal .multi-assign-block .remove-instance button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 15px;
    height: 15px;
    font-size: 8px;
    padding: 3px;
    border-radius: 50px;
    background: var(--primary-color);
    color: #ffffff;
}