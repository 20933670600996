.addButton {
  display: flex;
}

.button {
  height: 22pt;
  border-radius: 50px 50px 50px 50px;
  background-color: #000000;
  color: white;
  font-size: large;
  margin: 7px;
  position: fixed;
  margin-left: 92.4%;
}

@keyframes blinker {
  0% {
    background-color: #3969d5;
    box-shadow: 0 0 10px #878787;
  }
  50% {
    background-color: #3969d57a;
    box-shadow: 0 0 10px #8b8b8b;
  }
  100% {
    background-color: #3969d5;
    box-shadow: 0 0 10px #878787;
  }
}
@keyframes blinker-dot {
  0% {
    background-color: #3969d5;
    box-shadow: 0 0 10px #878787;
  }
  50% {
    background-color: #ffffff;
    box-shadow: 0 0 10px #8b8b8b;
  }
  100% {
    background-color: transparent;
    box-shadow: 0 0 10px #878787;
  }
}
#topbar-tabs-tabpane-financials > div {
  position: relative;
}
#topbar-tabs-tabpane-financials > div .btn.btn-primary {
  min-height: 35px;
}

.main-blinker {
  display: inline-block;
  position: absolute;
  right: 0px;
  top: -70px;
  z-index: 9999995;
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 10px #d2d2d2;
  transition: all 0.5s ease-in-out;
}

.main-blinker.hide {
  border-radius: 5px 5px 0 0;
  box-shadow: none;
  transition: all 0.5s ease-in-out;
}
.main-blinker > p {
  background-color: #3969d5;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  font-weight: 400;
  text-align: center;
  padding: 7px 20px;
  margin: 0;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  border-bottom: 1px solid #ececec50;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.main-blinker.hide > p span {
  position: relative;
  margin-right: 0px;
}
.main-blinker > p span {
  position: relative;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.main-blinker.hide > p span.count {
  background: #fff;
  color: #39695d;
  padding: 5px;
  display: inline-flex;
  font-size: 9px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  position: absolute;
  top: 5px;
  right: 7px;
  transition: all 0.5s ease-in-out;
}
.main-blinker > p span.count {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  color: #39695d;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  transition: all 0.5s ease-in-out;
}
/* .main-blinker.hide > p span:before{
    position: absolute;
    content: '';
    top: 0;
    right: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: blinker-dot 1s linear infinite;
}
.main-blinker > p span:before{
    position: absolute;
    content: '';
    top: 0;
    right: 2px;
    width: 6px;
    height: 6px;
    animation: none;
    border-radius: 50%;
} */
.main-blinker.hide ul {
  max-height: 0px;
  width: 0px;
  overflow: hidden;
  padding: 0;
}
.main-blinker ul {
  max-width: 270px;
  max-height: 300px;
  overflow: auto;
  padding: 5px;
  padding-left: 0px;
  text-align: left;
}
.main-blinker ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.main-blinker ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #3969d5;
}
.main-blinker ul::-webkit-scrollbar-thumb {
  background-color: #3969d5;
}
.main-blinker ul li {
  color: #3969d5;
  border-bottom: 1px solid #ececec;
  padding: 5px 10px 5px 15px;
  font-size: 12px;
  min-height: 30px;
  position: relative;
  overflow: hidden;
  display: block;
  text-overflow: inherit;
  white-space: initial;
}
.main-blinker ul li:hover {
  background-color: #ececec;
  cursor: pointer;
}
.main-blinker ul li span {
  display: inline-block;
}
/* .main-blinker ul li::before{
    position: absolute;
    content: '';
    top: calc(50% - 3px);;
    left: 5px;
    width: 6px;
    height: 6px;
    background-color: #3969d5;
    border-radius: 50%;
} */
.update-blinker {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: -36px;
  z-index: 999999;
  border-radius: 5px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0 10px #d2d2d2;
  transition: all 0.5s ease-in-out;
}
.update-blinker.hide {
  border-radius: 5px 5px 0 0;
  right: 0px;
  transition: all 0.5s ease-in-out;
}
/* .update-blinker.hide > p{
    animation: blinker 2s linear infinite;
} */
.update-blinker > p {
  background-color: #ffc107;
  color: #3d3d3d;
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding: 7px 20px;
  margin: 0;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  border-bottom: 1px solid #ececec50;
}
.update-blinker > p span {
  position: relative;
  /* animation: blinker 2s linear infinite; */
}
/* .update-blinker.hide > p span:before{
    position: absolute;
    content: '';
    top: 0;
    right: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: blinker-dot 2s linear infinite;
}
.update-blinker > p span:before{
    position: absolute;
    content: '';
    top: 0;
    right: 2px;
    width: 6px;
    height: 6px;
    animation: none;
    border-radius: 50%;
} */
.update-blinker.hide ul {
  max-height: 0px;
  overflow: hidden;
  padding: 0;
}
.update-blinker ul {
  max-width: 270px;
  max-height: 300px;
  overflow: auto;
  /* padding: 5px;
    padding-left: 0px; */
  text-align: left;
}
.update-blinker ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.update-blinker ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #ffc107;
}
.update-blinker ul::-webkit-scrollbar-thumb {
  background-color: #ffc107;
}
.update-blinker ul li {
  color: #3d3d3d;
  border-bottom: 1px solid rgba(255, 193, 7, 0.12);
  padding: 10px 10px 10px 20px;
  font-size: 12px;
  height: 36px;
  /* position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap; */
}
/* .update-blinker ul li::before{
    position: absolute;
    content: '';
    top: calc(50% - 3px);;
    left: 5px;
    width: 6px;
    height: 6px;
    background-color: #3d3d3d;
    border-radius: 50%;
} */
.update-blinker ul li:hover {
  cursor: pointer;
  background: rgba(255, 193, 7, 0.12);
}

/* @media (max-width: 1199px) {
    .main-blinker{
        top: -70px;
    }
} */

.view-draft-btn {
  border: 1px solid #3969d5;
  border-radius: 5px;
  color: #3969d5;
  background-color: white !important;
}
