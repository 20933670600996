#printBtn{
    display: inline-block;
    margin: 10px;
    border: none;
    padding: 10px;
    color: #ffffff;
    background: #0081f2;
    border-radius: 5px;
}
#printThis , #printThis > *{
    font-family: Helvetica, sans-serif;
}
.charting-page-block{
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    /* border: 1px solid #ececec;  */
    border-bottom: 0px;
    border-top: 0px;
}
.charting-page-block .removebtn{
    position: absolute;
    content: '';
    right: 7px;
    top: 7px;
    display: none;
    background: red;
    border: none;
    color: #fff;
    border-radius: 50px;
    padding: 10px;
    font-size: 13px;
}
 /* .charting-page-block:hover{ 
     border: 1px solid #ececec; 
     box-shadow: 0 0 10px #ececec inset;
    border-radius: 15px; 
 }  */

 .charting-page-block:hover .removebtn{
    display: block;
    z-index: 99;
}