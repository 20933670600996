.wi-33{
    width: calc(33.3333% - 4px)!important;
}
.wi-35{
    width: calc(35% - 4px)!important;
}
.wi-40{
    width: calc(40% - 4px)!important;
}
.wi-45{
    width: calc(45% - 4px)!important;
}
.wi-50{
    width: calc(50% - 4px)!important;
}
.wi-55{
    width: calc(55% - 4px)!important;
}
.wi-60{
    width: calc(60% - 4px)!important;
}
.wi-65{
    width: calc(65% - 4px)!important;
}
.wi-100{
    width: calc(100% - 4px)!important;
}
.he-5{
    height: calc(5% - 4px)!important;
}
.he-10{
    height: calc(10% - 4px)!important;
}
.he-15{
    height: calc(15% - 4px)!important;
}
.he-40{
    height: calc(40% - 4px)!important;
}
.he-45{
    height: calc(45% - 4px)!important;
}
.he-50{
    height: calc(50% - 4px)!important;
}
.he-55{
    height: calc(55% - 4px)!important;
}
.he-85{
    height: calc(85% - 4px)!important;
}
.he-90{
    height: calc(90% - 4px)!important;
}
.he-95{
    height: calc(95% - 4px)!important;
}
.he-100{
    height: calc(100% - 4px)!important;
}
.h2-5{
    height: calc(5%)!important;
}
.h2-10{
    height: calc(10%)!important;
}
.h2-15{
    height: calc(15%)!important;
}
.h2-30{
    height: calc(30%)!important;
}
.h2-40{
    height: calc(40%)!important;
}
.h2-45{
    height: calc(45%)!important;
}
.h2-50{
    height: calc(50%)!important;
}
.h2-55{
    height: calc(55%)!important;
}
.h2-60{
    height: calc(60%)!important;
}
.h2-65{
    height: calc(65%)!important;
}
.h2-85{
    height: calc(85%)!important;
}
.h2-90{
    height: calc(90%)!important;
}
.h2-95{
    height: calc(95%)!important;
}
.h2-100{
    height: calc(100%)!important;
}
.w2-33{
    width: calc(33.3333%)!important;
}
.w2-35{
    width: calc(35%)!important;
}
.w2-40{
    width: calc(40%)!important;
}
.w2-45{
    width: calc(45%)!important;
}
.w2-50{
    width: calc(50%)!important;
}
.w2-55{
    width: calc(55%)!important;
}
.w2-60{
    width: calc(60%)!important;
}
.w2-65{
    width: calc(65%)!important;
}
.w2-100{
    width: calc(100%)!important;
}
.mxy-3{
    margin: 2px;
}
.p-bg{
    background: #ffffff;
    position: relative;
    border: 1px solid #ececec;
}
.p-bg .btn-absolute{
    display: none;
    position: absolute;
    right: 5px;
    bottom: 0px;
    width: 40px;
    height: 24px;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border: none;
    background-color: #0081f2;
    cursor: pointer;
    border-radius: 5px;
    z-index: 99;
    font-size: 12px;
    color: #ffffff;
    font-weight: 400;
    box-shadow: 0 0 10px #fff;
}
.p-bg .edit-button.btn-absolute{
    right: 55px;
}
.p-bg:hover .btn-absolute{
    display: inline-flex;
}
.p-bg .content-box{
    overflow: hidden;
}
.p-bg .content-box .rich-content p{
    margin: 0;
    font-size: 12px;
    line-height: 1.1;
    margin-bottom: 7px;
    word-break: break-all;
}
.p-bg .content-box .rich-content ul{
    padding-left: 20px;
    font-size: 12px;
    line-height: 1.3;
}
.user-option{
    width: 100%;
    align-items: center;
    width: 100%;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.user-option button{
    width: 40px;
    height: 22px;
    font-size: 14px;
    margin: 2px 5px;
    padding: 3px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #e5f6fc;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    z-index: 99;
}
.user-option li svg{
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 0;
    width: 20px;
    height: 20px;
}
.user-option li:hover button svg{
    z-index: -1;
}
.user-option li button:hover svg path{
    fill: #ffffff
}
/* .user-option li span.icon-still{
    height: 15px;
    position: relative;
    width: 15px;
}
.user-option li span.icon-still svg{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.user-option li span.icon-hover{
    display: none;
}
.user-option li:hover span.icon-hover{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #0081f2;
    opacity: 0.5;
    border-radius: 5px;
} */


.pattern-content-append{
    max-width: 100%;
    display: inline-block;
    width: 100%;
}
.auto-volume-p1{
    position: relative;
    /* max-width: 991px; */
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
    background: #ffffff;
    font-family: sans-serif;
}
/* .printable .auto-volume-p1{
    background: #8f8c82;
} */
.auto-volume-p1 header{
    margin-top: 10px;
}
.auto-volume-p1 header .page-title{
    width: calc(100% - 180px);
    position: relative;
    padding: 5px;
}
.auto-volume-p1 header button{
    width: calc(100% - 8px);
    align-items: center;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 0;
    bottom: 4px;
    border: none;
    border-radius: 5px;
    background: #e5f6fc;
}
.auto-volume-p1 header .page-title .reset-button{
    display: none;
    position: absolute;
    left: calc(100% - 50px);
    top: 15px;
    width: 50px;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: none;
    background-color: #0081f2;
    cursor: pointer;
    border-radius: 5px;
    z-index: 99;
    font-size: 15px;
    color: #ffffff;
    font-weight: 300;
    box-shadow: 0 0 10px #fff;
}
.auto-volume-p1 header .page-title:hover .reset-button{
    display: inline-flex;
}
.auto-volume-p1 header .page-title h5{
    font-size: 20px;
    font-weight: 600;
    color: #000;
    margin: 10px auto;
}
.auto-volume-p1 header .brand-logo{
    padding: 0 0 0 15px;
}
.auto-volume-p1 header .brand-logo span{
    display: inline-block;
}
.auto-volume-p1 header .brand-logo span img{
    width: 80px;
    object-fit: contain;
}
.auto-volume-p1 .body-content-main{
    height: 670px;
}
.auto-volume-p1 .body-content-main .body-title{
    /* font-size: 30px; */
    /* font-weight: 600; */
    color: rgb(29,106,178);
    display: inline-block;
    /* padding: 15px 0; */
    margin: 0px auto;
    height: 54px;
    width: 100%;
    position: relative;
}
.auto-volume-p1 .body-content-main .body-title button{
    width: calc(100% - 8px);
    align-items: center;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 0;
    bottom: 0;
    border: none;
    border-radius: 5px;
    background: #e5f6fc;
}
.auto-volume-p1 .body-content-main .body-title .reset-button{
    display: none;
    position: absolute;
    left: calc(100% - 50px);
    top: 15px;
    width: 50px;
    height: 30px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: none;
    background-color: #0081f2;
    cursor: pointer;
    border-radius: 5px;
    z-index: 99;
    font-size: 15px;
    color: #ffffff;
    font-weight: 300;
    box-shadow: 0 0 10px #fff;
}
.auto-volume-p1 .body-content-main .body-title:hover .reset-button{
    display: inline-flex;
}
.auto-volume-p1 .body-content-main .body-title h3{
    /* font-size: 24px;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700; */
    font-size: 24px;
    margin-bottom: 0;
    line-height: 1.1;
    font-weight: 700;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
}
.auto-volume-p1 .body-content{
    height: 616px;
}
.auto-volume-p1 .body-content .features-points {
    margin-bottom: 20px;
}
.auto-volume-p1 .body-content .features-points li p{
    display: inline-block;
    margin: 0px auto 10px;
    font-size: 16px;
    font-weight: 500;
}
.auto-volume-p1 .body-content .p-bg .table-title{
    background: #002060;
    padding: 5px;
    display: block;
    font-size: 10.5px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 1px;
    /* font-family: 'Roboto', sans-serif; */
}
.auto-volume-p1 .body-content .p-bg table{
    width: 100%;
    height: 100%;
    margin-bottom: 0;
}
.auto-volume-p1 .body-content .p-bg table thead tr{
    background: #002060;
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 1px;
    /* font-family: 'Roboto', sans-serif; */
}
.auto-volume-p1 .body-content .p-bg table thead tr th{
    padding: 3px;
    border: 0.5px solid rgb(0, 0, 0);
}
.auto-volume-p1 .body-content .p-bg table tbody tr{
    background: #e9ebf5;
}
.auto-volume-p1 .body-content .p-bg table tbody tr:nth-child(even) {
    background: #cfd5ea;
}

.table td, .table th {
    font-weight:400;
}

.auto-volume-p1 .body-content .p-bg table tbody tr td{
    padding: 4px 3px;
    /* line-height: 10px; */
    border-right: 0.7px solid rgb(0, 0, 0);
    border-bottom: 0.7px solid rgb(0, 0, 0);
    /* font-family: 'Roboto', sans-serif; */
    text-align: right;
    font-size: 10px;
    color: #000;
    font-weight: 400;
}
.auto-volume-p1 .body-content .p-bg table tbody tr td:first-child{
    text-align: left;
}
.auto-volume-p1 .body-content .p-bg table tbody tr td b{
    font-size: 10px;
}
.auto-volume-p1 .body-content .reasearch-source p{
    margin-bottom: 0;
    font-size: 12px;
    font-style: italic;
}

.auto-volume-p1 footer{
    padding: 5px;
    /* border-top: 1px solid #adadad; */
    font-size: 11px;
    position: absolute;
    bottom: 0;
    width: 100%;
}
.auto-volume-p1 footer span{
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 0px;
    font-style: italic;
}
.auto-volume-p1 footer .footer-date span{
    text-align: left;
    font-style: normal;
}
.auto-volume-p1 footer .footer-count span{
    text-align: right;
    font-style: normal;
}
.auto-volume-p1 .body-content .category-graphs .graph-block{
    text-align: center;
    border: 2px dashed #b4b4b5;
    padding: 10px;
    height: 100%;
}
.auto-volume-p1 .body-content .category-graphs .graph-block .graph-title{
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 7px auto;
    color: #5e5e5e;
    font-size: 14px;
}
.auto-volume-p1 .body-content .category-graphs .graph-block span.chart{
    width: 100%;
    height: calc(100% - 35px);
    /* height: 200px; */
    align-items: end;
    position: relative;
}
.auto-volume-p1 .body-content .category-graphs .graph-block span.chart div{
    max-width: 100%;
    max-height: 100%;
}
.auto-volume-p1 .body-content .category-graphs .graph-block span.chart canvas{
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0 auto;
}
.auto-volume-p1 .body-content .category-graphs .graph-block img{
    width: 100%;
    margin: 0 auto;
}
.auto-volume-p1 .body-content .disclaimer-content p{
    font-size: 11px;
    line-height: 1.3;
    margin-bottom: 5px;
}
.auto-volume-p1 .body-content .disclaimer-content .sub-title{
    font-size: 12px;
    margin: 10px auto 0;
    color: rgb(29,106,178);
    font-weight: 600;
}
.auto-volume-p1 .body-content .disclaimer-content ul li p span{
    width: 50px;
    display: inline-block;
}
.auto-volume-p1 .body-content .disclaimer-content .contact-block .sub-title.black{
    font-size: 12px;
    margin: 0;
    color: rgb(0 0 0);
}
.auto-volume-p1 .body-content .disclaimer-content .contact-block ul li{
    line-height: 1.3;
}
.auto-volume-p1 .body-content .disclaimer-content .contact-block ul li p{
    margin: 5px auto;
}

/* box title */
.body-content .content-box h4{
    font-size: 12px;
    margin: 0;
    line-height: 1.1;
    padding: 2px 4px;
    background: #e5f6fc;
    color: #000000;
    font-weight: 600;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.body-content .content-box div canvas{
    font-size: 10px;
    padding: 2px;
}
/* .body-content .content-box div canvas:last-child{
    display: none;
} */

.table-modal input{
    width: 120px;
}
.table-modal input::placeholder{
    color: #adadad;
    font-weight: 300;
    text-align: center;
}
.Block-title-modal .modal-body{
    padding: 0 10px;
}
.Block-title-modal .modal-body textarea{
    margin: 0 auto;
    width: 100%;
    resize: none;
    border: 1px solid #e7e7e7;
}
.Block-title-modal .modal-body .remain-char{
    position: absolute;
    bottom: 7px;
    right: 12px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 12px;
}
.Block-title-modal .modal-body .remain-char.green{
    background-color: lightseagreen;
}
.Block-title-modal .modal-body .remain-char.yellow{
    background-color: yellow;
}
.Block-title-modal .modal-body .remain-char.red{
    background-color: red;
}

/* box title */

/* rich text */
.modal-backdrop{
    z-index: 1040999;
}
.modal{
    z-index: 1050999;
}
/* .Block-rich-modal .modal-dialog{
    width: 500px;
} */
.modal-dialog .modal-header{
    padding: 10px;
}
.modal-dialog .modal-header .modal-title{
    font-size: 16px;
}
.wrapper-class {
    padding: 1rem;
    border: 1px solid #ccc;
    min-height: 350px;
}
.editor-class {
    background-color:rgb(245, 245, 245);
    padding: 5px;
    border: 1px solid #ccc;
    height: 350px;
    /* overflow: scroll; */
}
.toolbar-class {
    border: 1px solid #ccc;
}
/* rich text */

/* chart modal */
.chart-type-modal .modal-body label{
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.chart-type-modal .modal-body label input{
    cursor: pointer;
}
