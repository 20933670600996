.keyupdate-sec{
    position: relative;
    margin: 10px;
    padding: 53px 0px 10px;
    max-height: 600px;
    /* width: 100%; */
    min-width: 300px;
    max-width: 480px;
    width: 35%;
    overflow: hidden;
    border: 0.25px solid var(--light-border-color);
    border-radius: 4px;
}

.valuation-box.main-box .title-block h4 {
    border-bottom: 1px solid var(--sharp-border-color);
    color: var(--table-heading-block-color);
    font-size: var(--table-heading-block-font-size);
    font-weight: var(--table-heading-block-weight);
    margin: 0;
    padding: var(--table-heading-block-inner-space);
    text-transform: capitalize;
}

.keyupdate-sec h3.title{
    border-bottom: 1px solid var(--light-border-color);
    color: var(--table-heading-block-color);
    font-size: var(--table-heading-block-font-size);
    font-weight: var(--table-heading-block-weight);
    margin: 0;
    padding: var(--table-heading-block-inner-space);
    text-transform: capitalize;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.keyupdate-sec h3.title span.icon{
    display: inline-flex;
    width: 25px;
    height: 25px;
    padding: 5px;
    background: #3969d5;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}
.keyupdate-sec h3.title span.icon svg{
    width: 10px;
    height: 13px;
}
.keyupdate-sec h3.title span.icon svg path{
    fill: #ffffff;
}
.keyupdate-sec .nav-tabs::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.keyupdate-sec .nav-tabs::-webkit-scrollbar{
    width: 2px;
    height: 4px;
    background-color: #F5F5F5;
}

.keyupdate-sec .nav-tabs::-webkit-scrollbar-thumb{
    background: #3969d5;
}
.keyupdate-sec .nav-tabs{
    border-bottom: 1px solid var(--sharp-border-color);
    padding: 0px 10px 0px;
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0; */
}
.keyupdate-sec .nav-tabs .nav-item button{
    padding: 4px 10px;
    border: none;
    background: none;
    color: #a6a6a6;
}
.keyupdate-sec .nav-tabs .nav-item button.active{
    /* background: #305e85; */
    background: #3969d5;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16.1px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}
.keyupdate-sec .tab-content{
    height: 100%;
    overflow: auto;
    width: 100%!important;
    padding: 0 5px;
}
.keyupdate-sec .tab-content::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.keyupdate-sec .tab-content::-webkit-scrollbar{
    width: 2px;
    height: 4px;
    background-color: #3969d5;
}

.keyupdate-sec .tab-content::-webkit-scrollbar-thumb{
    background-color: #3969d5;
}
.keyupdate-sec .tab-content .tab-pane ul{
    padding-bottom: 50px;
}
.keyupdate-sec .tab-content .tab-pane ul li{
    position: relative;
}
.keyupdate-sec .tab-content .tab-pane ul li:after{
    position: absolute;
    content: '';
    left: 0;
    right: 0px;
    top: 0px;
    bottom: 0;
    transition: 0.5s all;
}
.keyupdate-sec .tab-content .tab-pane ul li:hover:after{
    left: 0;
    transition: 0.5s all;
    background: #f7f7f7;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row{
    border-bottom: 0.25px solid var(--light-border-color);
    position: relative;
    z-index: 99;
    /* padding: 10px 0px; */
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img{
    padding: 0 5px 0 7px;
    /* border-right: 1px solid #ececec; */
    width: 60px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img span{
    width: 40px;
    height: 40px;
    background: #c4dffa;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* box-shadow: 0 0 10px #ececec; */
    -webkit-text-stroke: medium;
    font-size: 20px;
    font-weight: 400;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img span.red{
    /* background: #ff4343; */
    background: rgb(241, 0, 117);
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img span.orange{
    /* background: #fd8c02; */
    background: rgb(253, 126, 20);
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img span.grey{
    /* background: #7c7c7c; */
    background: rgb(95, 109, 136);
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img span.yellow{
    /* background: #ffff20; */
    background: rgb(16, 183, 89);
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img span.blue{
    /* background: #6495ed; */
    background: #3969d5;
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .user-img img{
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    /* box-shadow: 0 0 10px #ececec; */
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data{
    width: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 10px;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data h6{
    color: #000000a3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 2px;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .news-content{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    font-size: 13px;
    color: #A6A6A6;
    color: #B1B1B1;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta{
    position: relative;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta .label-text{
    padding: 4px 7px;
    /* box-shadow: 0 0 10px #ececec; */
    border-radius: 3px;
    font-size: 12px;
    font-weight: 400;
    min-width: 70px;
    text-transform: capitalize;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta .label-text.red{
    /* background: #ff4343; */
    background: #f10075;
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta .label-text.orange{
    /* background: #fd8c02; */
    background: rgb(253, 126, 20);
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta .label-text.grey{
    /* background: #7c7c7c; */
    background: rgb(95, 109, 136);
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta .label-text.yellow{
    /* background: #ffff20; */
    background: rgb(16, 183, 89);
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta .label-text.blue{
    /* background: #6495ed; */
    background: #3969d5;
    color: #ffffff;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .update-meta .post-date{
    font-size: 13px;
    /* margin-left: 20px; */
    margin-left: 10px;
    color: #A6A6A6;
    /* padding-left: 20px; */
    padding-left: 10px;
    border-left: 1px solid #3969d5;
}
.keyupdate-sec .tab-content .tab-pane ul li .news-row .news-data .read-update{
    font-size: 13px;
    color: #3969d5;
    text-decoration: underline;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 3px;
}


@media screen and (max-width: 991px){
    .keyupdate-sec{
        max-width: 100%;
        width: 100%;
    }
}
