.user-main {
  flex-direction: column;
}
.user-main .add-user-filter {
  padding: 10px 10px 0;
  justify-content: flex-end;
}
.user-input-block {
  position: relative;
  margin: 10px 0;
  padding: 45px 5px 10px;
  overflow: hidden;
  border: 1px solid var(--light-border-color);
  width: calc(100% - 20px);
  border-radius: 5px;
}
.user-input-block .user-filter-main {
  padding: 10px 10px 0;
}
.user-input-block .user-filter-main .user-filter-title .title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 12px;
  border-bottom: 0.25px solid var(--sharp-border-color);
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
}
.user-input-block .user-filter-main .form-group label{
  font-size: 16px; 
  color: var(--primary-text-normal);
}
.user-input-block .user-filter-main .form-group .form-control {
  border: 0.25px solid var(--light-border-color);
  color: var(--primary-text-normal);
}
.project-input-block {
  position: relative;
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}
.project-input-block .project-filter-main {
  width: 250px;
  margin: 30px 50px 30px 0;
  flex-direction: column;
  padding: 5px;
}
.project-input-block .project-filter-main .project {
  width: 100%;
  padding: 5px;
  /* border: 0.25px solid rgba(57,109,213,.75); */
  border-radius: 4px;
}
.project-input-block .project-filter-main .project label {
  display: flex !important;
  margin: 0;
  justify-content: flex-start;
  font-size: 15px;
  font-weight: 600;
  border: solid 0.25px var(--light-border-color);
  line-height: 1;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  padding: 5px;
  border-radius: 5px;
  color: var(--primary-text-normal);
}
.project-input-block .project-filter-main .project label input {
  width: 20px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.project-input-block .project-filter-main .project label input:checked{
  position: relative;
  border: none;
}
.project-input-block .project-filter-main .project label input:checked:before{
  position: absolute;
  content: '';
  width: 17px;
  height: 17px;
  background-color: var(--primary-color);
  border-radius: 3px;
  left: 1px;
  bottom: 0;
  top: -1px;
}
.project-input-block .project-filter-main .project label input:checked:after{
  position: absolute;
  content: '\2713';
  color: #fff;
  width: 12px;
  height: 10px;
  left: 3px;
  right: 0;
  font-size: 13px;
  transform: rotate(18deg);
  top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-input-block .project-filter-main .access-options {
  padding: 0 12px;
}
.project-input-block .project-filter-main .access-options label {
  display: flex !important;
  margin: 0px 0px 10px;
  justify-content: flex-start;
  font-size: 15px;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 400;
  cursor: pointer;
  color: var(--primary-text-normal);
  letter-spacing: 0.5px;
}
.project-input-block .project-filter-main .access-options label input {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.project-input-block .project-filter-main .access-options label input:checked{
  position: relative;
  border: none;
}
.project-input-block .project-filter-main .access-options label input:checked:before{
  position: absolute;
  content: '';
  width: 15px;
  height: 15px;
  background-color: var(--primary-color);
  border-radius: 50%;
}
.project-input-block .project-filter-main .access-options label input:checked:after{
  position: absolute;
  content: '\2713';
  color: #fff;
  width: 7px;
  height: 7px;
  left: 3px;
  right: 0;
  font-size: 11px;
  transform: rotate(18deg);
  top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-main .add-user-filter .add-user {
  background-color: var(--primary-color);
}
button.add-user-btn {
  background-color: var(--primary-color);
  min-width: 100px;
}
.user-main .add-user-filter button.add-role {
  display: inline-block;
  height: 40px;
}
.user-table-sec {
  position: relative;
  margin: 10px;
  padding: 45px 2px 0px;
  overflow: hidden;
  border: 0.25px solid var(--light-border-color);
  border-radius: 4px;
  width: calc(100% - 20px);
  border-color: var(--light-border-color) !important;
}
.user-table-sec .title {
  margin: 0;
  border-bottom: 0.25px solid var(--light-border-color);
  position: absolute;
  top: 0;
  padding: 12px;
  left: 0;
  right: 0;
  font-size: var(--table-heading-block-font-size);
  text-transform: capitalize;
  font-weight: var(--table-heading-block-weight);
}
.user-table-sec .table thead tr {
  border-bottom: 2px solid rgba(57, 109, 213, 0.75);
  border-top: 0px;
}
.user-table-sec .table thead th {
  border-bottom: 0px;
  border-top: 0px;
  color: #3d3d3d;
  font-weight: 400;
}
.user-table-sec table tr {
  border-bottom: 0.25px solid var(--light-border-color);
}
.user-table-sec table tr td {
  vertical-align: middle;
  font-size: 13px;
  color: #3d3d3d;
  padding: 5px 10px;
  border: none;
}
.user-table-sec table tr td .bullet-text li span {
  background: #ececec;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
}
.user-table-sec table tr td .toggle-switch {
  justify-content: center;
  min-width: 150px;
}
.user-table-sec input {
  cursor: pointer;
}
.user-table-sec table tr td button {
  border-radius: 4px;
  cursor: pointer;
  margin: 3px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.user-table-sec table tr td button.action {
  width: 25px;
  height: 25px;
  border: 0.25px solid var(--light-border-color);
  color: #356299;
  background: transparent;
  position: relative;
  z-index: 99;
}
.user-table-sec table tr td button.action:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  background: transparent;
  z-index: 9;
}
.user-table-sec table tr td button.action:hover:before {
  /* background: rgba(57,109,213,.75); */
  opacity: 0.5;
}
.user-table-sec table tr td button.action:hover:before svg path {
  fill: #ffffff;
}
.user-table-sec table tr td button.action svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: translate(6px, 6px);
  z-index: 0;
}
.user-table-sec table tr td button svg path {
  fill: rgba(57, 109, 213, 0.75);
}
.modal-dialog .modal-header .modal-title {
  font-size: 20px;
  font-weight: 600;
}
.view-user-modal .user-block {
  width: 225px;
  margin: 10px;
}
.view-user-modal .user-block .user-title {
  margin: 0;
  border: 0.25px solid var(--light-border-color);
  font-size: 20px;
  font-weight: 500;
  padding: 5px 10px;
  border-color: var(--light-border-color);
}
.view-user-modal .user-block ul {
  padding-left: 10px;
}
.view-user-modal .user-block ul li span {
  font-size: 12px;
}
.add-role-modal .user-roles .user-role-data {
  width: 135px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.25px solid var(--light-border-color);
  border-radius: 10px;
  font-size: 12px;
  text-transform: capitalize;
  overflow: hidden;
  font-weight: 300;
}
.add-role-modal .user-roles .user-role-data .title {
  padding: 5px;
  color: #ffffff;
  background-color: rgba(57, 109, 213, 0.75);
  display: inline-block;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--light-border-color);
  line-height: 1;
}
.add-role-modal .user-roles .user-role-data .data {
  padding: 5px;
  color: #000000;
}

.hLGqlg .sc-gsDKAQ.fbhklE.rdt_TableHead {
  font-size: var(--table-heading-block-font-size);
  /* border-bottom: 1px solid var(--sharp-border-color) !important ; */
  border-bottom: var(--sharp-border);
}

.edit-action {
  border: 0.25px solid #3969d585;
  border-radius: 5px;
  padding: 5px;
}

#row-1 {
  border-bottom: var(--light-border);
}

.sc-dkPtRN.hCBnvI.rdt_TableHeadRow
  .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol {
  justify-content: center !important;
}

.sc-dkPtRN.hCBnvI.rdt_TableHeadRow
  .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol:first-child {
  justify-content: left !important;
  max-width: fit-content;
}

.sc-jrQzAO.fhCFWO.rdt_TableRow
  .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell {
  justify-content: center !important;
}

.sc-jrQzAO.fhCFWO.rdt_TableRow
  .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell:first-child {
  justify-content: left !important;
  max-width: fit-content;
}
