.filter-form-block .form-group.field-wrap{
    border: 0.25px solid var(--light-border-color);
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 14px;
}
.filter-form-block .form-group.field-wrap label{
    color: var(--primary-text-dark);
    position: relative;
    font-size: 14px;
}
.filter-form-block .form-group.field-wrap .css-1s2u09g-control , .filter-form-block .form-group.field-wrap .css-1insrsq-control , .filter-form-block .form-group.field-wrap > div > div:first-child{
    border: none!important;
    border-left: 0.25px solid var(--light-border-color)!important;
    border-radius: 0px 5px 5px 0px;
    max-height: 35px;
    min-height: 35px;
}
.filter-form-block .form-group.field-wrap label span.hierarchy-level{
    position: absolute;
    left: -5px;
    top: -15px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #3969d5;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 1;
    font-weight: 100;
    text-align: center;
}