/* Buttons */

.gray-table-btn {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  height: 33px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gray-table-btn:focus{
  border: none;
  border-radius: 5px;
}


.user-managment-section .rdt_TableRow {
  font-size: 12px !important;
  min-height: 45px !important;
}
.hCBnvI.rdt_TableHeadRow{
  min-height: 42px;
  border: none;
}
.user-table-sec .sc-dkPtRN.hCBnvI.rdt_TableHeadRow .sc-crHmcD.bNgcsh.rdt_TableCol_Sortable{
  color: var(--primary-grey);
  font-size: 16px;
  font-weight: 400;
}