.switch {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 10px;
  width: 45px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1d4283;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1d4283;
}

/* input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
} */

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.custom-active-switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-actie-switch label {
  padding: 0 !important;
  margin: 0 !important;
}

.status-label {
  font-size: 12px;
  font-weight: 600;
  color: #ccc;
}

.status-label.active {
  color: #000000a3;
  transition: 0.3s;
}

.slider.round.active {
  background-color: #1d4283;
}

.slider.round.active:before {
  left: 24px;
}
