.table-filter-block {
    position: relative;
}
.table-filter-block input{
    width: 250px;
    border-radius: 2px;
    border: 0.25px solid var(--light-border-color);
    padding: 5px;
    font-size: 12px;
}
.table-filter-block input:focus , .table-filter-block input:focus-visible{
    border: 0.25px solid var(--light-border-color);
    box-shadow: none;
}
.table-filter-block button{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 30px;
    border: none;
    font-size: 12px;
    color: #ffffff;
    background: #718cde;
    border-radius: 0 2px 2px 0;
}