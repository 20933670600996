.dashboard .dashboard-content .financial-data-sheet::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.dashboard .dashboard-content .financial-data-sheet::-webkit-scrollbar{
    width: 6px;
    height: 6px;
    background-color: #3969d5;
}

.dashboard .dashboard-content .financial-data-sheet::-webkit-scrollbar-thumb{
    background-color: #3969d5;
}
.financial-data-sheet table thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2; 
}

.financial-data-sheet table thead th::nth-child(2) {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
}

.financial-data-sheet table thead th::nth-child(3) {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
}

.editable-table-cell {
    border: none;
    text-align: right;
    width: 100px;
}

.editable-table-cell.validation-failed{
    border: 1px dashed red !important;
    color: #ff0000;
    position: relative;
}

.editable-table-cell.validation-failed:before{
    position: absolute;
    content: 'required';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #ff0000;
}


.financial-data-sheet .table-inner-cell {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 8px;
    border-right: 0.25px solid var(--light-border-color);
    align-items: center;
}

.financial-data-sheet .table-inner-cell.first {
    border-left: 0.25px solid var(--light-border-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.financial-data-sheet .table-inner-cell.second {
    border-left: 0.25px solid var(--light-border-color);
}
.financial-data-sheet .table-inner-cell.third {
    border-left: 0.25px solid var(--light-border-color);
    /* border-right: 2px solid var(--light-border-color); */
    position: relative;
}
.financial-data-sheet .table-inner-cell.third:after{
    position: absolute;
    content: '';
    right: 0px;
    bottom: 0;
    top: 0;
    width: 3px;
    box-shadow: 0 0 10px var(--light-border-color) inset;
}


.financial-data-sheet .label-column-headers .table-inner-cell.first {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 0.25px solid var(--light-border-color);
    border-bottom: 0.25px solid var(--light-border-color);
    /* box-shadow: 0 0 5px #3969d585 inset; */
}

.financial-data-sheet .label-column-headers .table-inner-cell.second {
    border-left: 0.25px solid var(--light-border-color);
    border-bottom: 0.25px solid var(--light-border-color);
    /* box-shadow: 0 0 5px #3969d585 inset; */
}
.financial-data-sheet .label-column-headers .table-inner-cell.third {
    border-left: 0.25px solid var(--light-border-color);
    border-bottom: 0.25px solid var(--light-border-color);
    /* box-shadow: 0 0 5px #3969d585 inset; */
}

.editable-table-cell:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid #0069d9;
}