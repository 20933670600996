.keyupdate-main {
  width: 100%;
  flex-direction: column;
  padding: 10px 30px;
  font-family: "lato", sans-serif;
}

.keyupdate-main .add-key-filter {
  margin: 0 0 10px;
  justify-content: flex-end;
}

.keyupdate-main .add-key-filter button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
}

.keyupdate-main .add-key-filter button.add-category {
  border: var(--light-border);
  background-color: transparent;
  /* color: #3969D5; */
  color: var(--primary-text-normal) !important;
  margin-right: 5px;
}

.keyupdate-main .add-key-filter button.add-keyupdates {
  background-color: #3969d5;
}

.keyupdate-main .keyupdate-sec {
  min-width: 100%;
  border: 0.25px solid var(--keyupdate-border-color);
  margin: 0;
  max-height: initial;
  padding: 0;
}

.keyupdate-main .keyupdate-sec .title {
  font-size: var(--title-fontSize);
  font-weight: var(--title-weight);
  color: var(--title-color);
  position: relative;
  border-bottom: 0.25px solid var(--light-border-color);
  padding: var(--title-padding);
}

.keyupdate-main .rdt_TableHead .rdt_TableHeadRow {
  /* border-bottom: 2px solid var(--keyupdate-border-color); */
  border-bottom: none;
  min-height: 35px;
}

.keyupdate-main .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 12px;
  font-weight: 400;
  color: #3d3d3d;
}

.keyupdate-main .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child,
.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  text-align: left;
  justify-content: start;
  max-width: 50px;
}

.keyupdate-main .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol,
.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  text-align: center;
  justify-content: center;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow {
  border-bottom: 0.25px solid var(--keyupdate-border-color) !important;
  min-height: 35px;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell div span {
  padding: 4px 7px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  min-width: 70px;
  text-transform: capitalize;
  display: inline-block;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell div span.red {
  background: rgb(241, 0, 117);
  color: #ffffff;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell div span.orange {
  background: rgb(253, 126, 20);
  color: #ffffff;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell div span.grey {
  background: rgb(95, 109, 136);
  color: #ffffff;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell div span.green {
  background: rgb(16, 183, 89);
  color: #ffffff;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell div span.blue {
  background: #3969d5;
  color: #ffffff;
}

.keyupdate-main .rdt_TableBody .rdt_TableRow .rdt_TableCell span.action {
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 0.25px solid var(--keyupdate-border-color);
  border-radius: 3px;
  margin: 0 2.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keyupdate-main .keyupdate-sec nav.rdt_Pagination {
  border: none !important;
}

.keyupdate-main .keyupdate-sec nav.rdt_Pagination select {
  border: 0.25px solid var(--keyupdate-border-color);
  border-radius: 3px;
}

.keyupdate-main .categories {
  min-width: 100%;
  border: 0.25px solid var(--keyupdate-border-color);
  margin: 0 0 15px;
  max-height: initial;
  padding: 0;
  border-radius: 5px;
}

.keyupdate-main .categories .title {
  font-size: var(--title-fontSize);
  font-weight: var(--title-weight);
  color: var(--title-color);
  position: relative;
  border-bottom: 0.25px solid var(--title-bottom-border);
  padding: var(--title-padding);
  position: relative;
}

.keyupdate-main .categories .category-list {
  max-height: 205px;
  overflow-y: auto;
  padding: 10px 10px 5px 10px;
}

.keyupdate-main .categories .category-list ul li {
  padding-bottom: 10px;
  display: inline-block;
  width: 100%;
}

.keyupdate-main .categories .category-list ul li button {
  padding: 5px 7px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  height: 30px;
  border: none;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.keyupdate-main .categories .category-list ul li button span {
  overflow: hidden;
}

.keyupdate-main .categories .category-list ul li button.red {
  background: rgb(241, 0, 117);
  color: #ffffff;
}

.keyupdate-main .categories .category-list ul li button.orange {
  background: rgb(253, 126, 20);
  color: #ffffff;
}

.keyupdate-main .categories .category-list ul li button.grey {
  background: rgb(95, 109, 136);
  color: #ffffff;
}

.keyupdate-main .categories .category-list ul li button.green {
  background: rgb(16, 183, 89);
  color: #ffffff;
}

.keyupdate-main .categories .category-list ul li button.blue {
  background: rgb(1, 104, 250);
  color: #ffffff;
}

.keyupdate-main .categories .category-list ul li button p span {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.keyupdate-main .categories .category-list ul li button p span svg {
  width: 15px;
  height: 15px;
  margin: 0 5px;
}

.edit-category-modal input,
.add-category-modal input {
  text-align: left;
}

.edit-category-modal button.btn-primary,
.add-category-modal button.btn-primary {
  background-color: var(--primary-color);
}

.edit-category-modal input::placeholder,
.add-category-modal input::placeholder {
  text-align: left;
}

.keyupdate-main .events {
  min-width: 100%;
  border: 0.25px solid var(--keyupdate-border-color);
  margin: 0 0 15px;
  max-height: initial;
  padding: 0;
  border-radius: 5px;
}

.keyupdate-main .events .title {
  font-size: var(--title-fontSize);
  font-weight: var(--title-weight);
  color: var(--title-color);
  position: relative;
  border-bottom: 0.25px solid var(--title-bottom-border);
  padding: var(--title-padding);
  position: relative;
  margin: 0px;
}

.keyupdate-main .events .event-list {
  max-height: 300px;
  overflow-y: scroll;
  padding: 15px 15px;
}

.keyupdate-main .events .event-list ul li {
  padding: 5px 7px;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  margin: 5px 0;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--light-border);
}

.keyupdate-main .events .event-list ul li .event-detail {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
}

.keyupdate-main .events .event-list ul li .event-detail h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-text-normal);
}

.keyupdate-main .events .event-list ul li .event-detail span {
  font-size: 12px;
  font-weight: 400;
  /* color: #84868B; */
  color: var(--primary-text-light);
}

.keyupdate-main .events .event-list ul li .event-date {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
  color: var(--keyupdate-date-color);
}

.keyupdate-main .events .event-list ul li .event-date:before {
  position: absolute;
  content: "";
  left: -5px;
  top: 11px;
  width: 6px;
  height: 6px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.keyupdate-main .events .event-list ul li .event-date-icon {
  border-radius: 3px;
  width: 30px;
  height: 30px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(166, 166, 166);
}

.keyupdate-main .events .event-list ul li .event-date-icon svg {
  width: 15px;
  height: 15px;
}

.keyupdate-main .events .event-list ul li .event-date-icon svg path {
  fill: #ffffff;
}

.keyupdate-main .birthdays {
  min-width: 100%;
  border: 0.25px solid var(--keyupdate-border-color);
  margin: 0 0 15px;
  max-height: initial;
  padding: 0;
  border-radius: 5px;
}

.keyupdate-main .birthdays .title {
  font-size: 15px;
  font-weight: 700;
  /* color: #3D3D3D; */
  color: var(--primary-text-normal);
  position: relative;
  border-bottom: 0.25px solid var(--title-bottom-border);
  padding: 10px;
  margin: 0px;
}

.keyupdate-main .birthdays .birthday-list {
  padding: 10px 15px;
  max-height: 200px;
  overflow-y: scroll;
}

.keyupdate-main .birthdays .birthday-list ul li {
  padding: 5px 7px;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  margin: 0;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: var(--light-border);
}

.keyupdate-main .birthdays .birthday-list ul li:last-child {
  border: none;
}

.keyupdate-main .birthdays .birthday-list ul li .birthday-detail {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}

.keyupdate-main .birthdays .birthday-list ul li .birthday-detail .birthday-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #dddddd;
    object-fit: contain;
    object-position: center;
}

.keyupdate-main .birthdays .birthday-list ul li .birthday-detail .birthday-pic img {
    width: 100%;
    border-radius: 50%;
}

.keyupdate-main .birthdays .birthday-list ul li .birthday-detail h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  /* color: #3D3D3D; */
  color: var(--primary-text-normal);
}

.keyupdate-main .birthdays .birthday-list ul li .birthday-detail span {
  font-size: 11px;
  font-weight: 400;
  color: #84868b;
}

.keyupdate-main .birthdays .birthday-list ul li .birthday-date {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 700;
  padding: 5px;
  border-radius: 5px;
  color: var(--primary-text-normal);
}

.addkey-main-block {
  width: 100%;
  flex-direction: column;
  padding: 15px 30px;
  font-family: "lato", sans-serif;
}

.addkey-main-block .addkey-main {
  border: 0.25px solid var(--keyupdate-border-color);
  border-radius: 5px;
}

.addkey-main-block .addkey-main .title {
  font-size: 16px;
  font-weight: 700;
  color: var(--primary-text-normal);
  padding: 8px 10px;
  margin: 0;
  position: relative;
  border-bottom: 0.25px solid var(--keyupdate-border-color);
}

.addkey-main-block .post-by, .editkey-main-block .post-by{
    position: absolute;
    bottom: -50px;
    right: 0;
    display: inline-block;
}
.addkey-main-block .post-by p , .editkey-main-block .post-by p{
    font-size: 14px;
    font-weight: 400;
    padding: 5px 10px;
    color: var(--primary-text-normal);
    margin: 0;
    line-height: 1; 
    position: relative;
}

/* .addkey-main-block .addkey-main .post-by p strong{
    color: var(--primary-color);
} */

.addkey-main-block .addkey-main .form-block {
  padding: 0px 12px;
}

.addkey-main-block .addkey-main .form-block .primary-select label::after {
  content: ">";
  font: 17px "Consolas", monospace;
  color: var(--light-black);
  transform: rotate(90deg);
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 999999;
  bottom: -31px;
  right: 15px;
}

.addkey-main-block .addkey-main .default-fields , .editkey-main-block .default-fields{
    position: relative;
}

.addkey-main-block label , .editkey-main-block label{
    font-size: 16px;
    font-weight: 400;
    color: var(--primary-text-normal);
    padding: 0px 5px;
    margin-bottom: 2px;
}

.addkey-main-block .addkey-main .default-fields input, .addkey-main-block .addkey-main .primary-select select , .addkey-main-block .addkey-main .default-fields textarea , .editkey-main-block .editkey-main .default-fields input, .editkey-main-block .editkey-main .primary-select select , .editkey-main-block .editkey-main .default-fields textarea {
    border: 0.25px solid var(--keyupdate-border-color);
    margin-bottom: 20px;
    color: var(--primary-text-normal);
}
.addkey-main-block .addkey-main .default-fields label,
.addkey-main-block .addkey-main .primary-select label {
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-text-normal);
  padding: 0px 5px;
  margin-bottom: 2px;
}

.addkey-main-block .addkey-main .default-fields textarea {
  resize: none;
  border: 0.25px solid var(--keyupdate-border-color);
  min-height: 150px;
}

.addkey-main-block .addkey-main .rich-block {
  /* border: 0.25px solid var(--keyupdate-border-color); */
  border-radius: 0.25rem;
  overflow: hidden;
}

.addkey-main-block .addkey-main .rich-block > div {
  padding: 0px;
}

.addkey-main-block .addkey-main .rich-block > div .toolbar-class {
  border: none;
  border-bottom: 0.25px solid var(--keyupdate-border-color);
}

.addkey-main-block .addkey-main .rich-block > div .editor-class {
  border: none;
  background: #ffffff;
  overflow: auto;
  height: auto;
  color: var(--primary-text-normal);
}

.addkey-main-block
  .addkey-main
  .rich-block
  > div
  .editor-class::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.addkey-main-block
  .addkey-main
  .rich-block
  > div
  .editor-class::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  background-color: #f5f5f5;
}

.addkey-main-block
  .addkey-main
  .rich-block
  > div
  .editor-class::-webkit-scrollbar-thumb {
  background-color: #3969d5;
}

.editkey-main-block {
  width: 100%;
  flex-direction: column;
  padding: 20px 30px;
  font-family: "lato", sans-serif;
}

.editkey-main-block .editkey-main {
  border: 0.25px solid var(--keyupdate-border-color);
}

.editkey-main-block .editkey-main .title {
  font-size: 16px;
  font-weight: 700;
  color: #3d3d3d;
  padding: 12px;
  margin: 0;
  position: relative;
  border-bottom: 0.25px solid var(--keyupdate-border-color);
}

/* .editkey-main-block .editkey-main .post-by p {
    font-size: 16px;
    font-weight: 400;
    padding: 12px;
    color: #3D3D3D;
    margin: 0;
    line-height: 1;
    position: relative;
    border-bottom: 0.25px solid var(--keyupdate-border-color);
} */

.editkey-main-block .editkey-main .form-block {
  padding: 0px 12px;
}

/* .editkey-main-block .editkey-main{
    padding: 12px;
} */
.editkey-main-block .editkey-main .default-fields label {
  font-size: 16px;
  font-weight: 400;
  color: #3d3d3d;
  padding: 0px 5px;
}

.editkey-main-block .editkey-main .default-fields input {
  border: 0.25px solid var(--keyupdate-border-color);
  /* margin-bottom: 2%; */
}

.editkey-main-block .editkey-main .default-fields textarea {
  resize: none;
  border: 0.25px solid var(--keyupdate-border-color);
  min-height: 150px;
}

.editkey-main-block .editkey-main .rich-block {
  /* border: 0.25px solid var(--keyupdate-border-color); */
  border-radius: 0.25rem;
  overflow: hidden;
}

.editkey-main-block .editkey-main .rich-block > div {
  padding: 0px;
}

.editkey-main-block .editkey-main .rich-block > div .toolbar-class {
  border: none;
  border-bottom: 0.25px solid var(--keyupdate-border-color);
}

.editkey-main-block .editkey-main .rich-block > div .editor-class {
  border: none;
  background: #ffffff;
  overflow: auto;
  height: auto;
}

.editkey-main-block
  .editkey-main
  .rich-block
  > div
  .editor-class::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.editkey-main-block
  .editkey-main
  .rich-block
  > div
  .editor-class::-webkit-scrollbar {
  width: 2px;
  height: 4px;
  background-color: #f5f5f5;
}

.editkey-main-block
  .editkey-main
  .rich-block
  > div
  .editor-class::-webkit-scrollbar-thumb {
  background-color: #3969d5;
}

.addkey-main-block .addkey-main .btn-link,
.editkey-main-block .editkey-main .btn-link {
  color: #3969d5;
  text-decoration: none;
}

.addkey-main-block .addkey-main .btn-primary,
.editkey-main-block .editkey-main .btn-primary {
  background: #3969d5;
}

.keyupdates-select-category {
  border-bottom: 1px solid var(--sharp-border-color);
  padding: 12px;
}

.keyupdates-select-category input,
.keyupdates-select-category p {
  margin: 0;
}

.keyupdates-select-category select {
  width: 15%;
  height: auto;
  margin-left: 10px;
  border: var(--light-border);
}

.keyupate-add-new-page {
  background: none;
  border: none;
  color: lightblue;
  margin: 10px 0px;
}

.keyupdate-detail-sec .title{
    font-size: 30px;
    color: var(--primary-color);
    font-weight: 700;
    padding-bottom: 10px;
    padding-left: 15px;
    margin-bottom: 15px;
    border-bottom: 0.25px solid var(--sharp-border-color);
}
.keyupdate-detail-sec .keyupdates-detail-meta .date-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-text-normal);
    text-transform: capitalize;
    margin: 0;
    line-height: 1;
}
.keyupdate-detail-sec .keyupdates-detail-meta .date-block .line{
    height: 0.5px;
    width: 30px;
    background: var(--primary-color);
}
.keyupdate-detail-sec .keyupdates-detail-meta .poster-meta{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}
.keyupdate-detail-sec .keyupdates-detail-meta .poster-meta span.poster-img{
    margin-right: 15px;
}
.keyupdate-detail-sec .keyupdates-detail-meta .poster-meta span.poster-img img{
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
}
.keyupdate-detail-sec .keyupdates-detail-meta .poster-meta p{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
}
.keyupdate-detail-sec .keyupdates-detail-meta .poster-meta p span{
    font-size: 12px;
    color: var(--primary-grey);
    text-transform: capitalize;
}
.keyupdate-detail-sec .keyupdates-detail-meta .poster-meta p span.poster-name{
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-text-normal);
}
.keyupdate-detail-sec .keyupdates-detail-meta .post-category{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
}
.keyupdate-detail-sec .keyupdates-detail-meta .post-category span{
    padding: 5px 15px;
    border-radius: 5px;
    background: #5f6d88;
    font-size: 12px;
    text-transform: capitalize;
    color: #ffffff;
}
.keyupdate-detail-sec .keyupdates-detail-content p{
    color: var(--primary-text-normal);
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 20px;
}
.keyupdate-detail-sec .keyupdates-detail-content p.quote-block{
    color: var(--primary-grey);
    font-size: 24px;
    font-weight: 700;
}

@media screen and (max-width: 991px) {
    .keyupdate-main{
        padding: 15px;
    }
    .keyupdate-detail-sec .keyupdates-detail-meta{
        margin-bottom: 20px;
    }
    .keyupdate-detail-sec .keyupdates-detail-meta .date-block , .keyupdate-detail-sec .keyupdates-detail-meta .poster-meta , .keyupdate-detail-sec .keyupdates-detail-meta .post-category{
        justify-content: flex-start;
    }
    .keyupdate-detail-sec .keyupdates-detail-meta .date-block .line{
        margin: 0 5px;
    }
}