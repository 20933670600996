.assign-view-block {
  display: flex;
  flex-wrap: wrap;
}
.assign-view-block .assign-items {
  margin-bottom: 15px;
  /* border-bottom: 1px solid #ececec; */
  padding-bottom: 15px;
}
.assign-view-block h3 {
  font-size: 16px;
  color: var(--primary-text-normal);
  font-weight: 600;
  margin: 0;
}
.assign-view-block li {
  font-size: 12px;
  text-transform: capitalize;
  color: var(--primary-text-normal);
  line-height: 1;
  padding: 5px;
}
.assign-view-block li:not(:last-child) {
  border-right: 1px solid rgb(144 181 255);
}
.view-assigned-user-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
