.sidebar-panel{
    width: 50px;
    height: calc(100vh - 60px);
    box-shadow: 0 10px 25px #ececec;
    /* border-right: 0.25px solid rgba(57,109,213,.75); */
    transition: 0.5s all;
    position: absolute;
    left: 0;
    z-index: 999;
    background: #ffffff;
    overflow-y: auto;
}
.sidebar-panel::-moz-scrollbar-track{
    -moz-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    -moz-background-color: #F5F5F5;
    -moz-background: #F5F5F5;
}
.sidebar-panel::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    -webkit-background-color: #F5F5F5;
    -webkit-background: #F5F5F5;
}
.sidebar-panel::-webkit-scrollbar{
    width: 2px;
    height: 4px;
    background-color: #F5F5F5;
    -webkit-background-color: #F5F5F5;
    -webkit-background: #F5F5F5;
}
.sidebar-panel::-moz-scrollbar{
    width: 2px;
    height: 4px;
    background-color: #F5F5F5;
    -moz-background-color: #F5F5F5;
    -moz-background: #F5F5F5;
}
.sidebar-panel::-webkit-scrollbar-thumb{
    background-color: rgba(57,109,213,.75);
    background: rgba(57,109,213,.75);
    -webkit-background-color: rgba(57,109,213,.75);
    -webkit-background: rgba(57,109,213,.75);
}
.sidebar-panel::-moz-scrollbar-thumb{
    background-color: rgba(57,109,213,.75);
    background: rgba(57,109,213,.75);
    -moz-background-color: rgba(57,109,213,.75);
    -moz-background: rgba(57,109,213,.75);
}
.sidebar-panel:hover{
    width: 300px;
    transition: 0.5s all;
    z-index: 9999999;
    /* color: aqua !important; */
}
.sidebar-panel .accordion .card{
    border: 0px;
    border-bottom: 0.25px solid #92959e95;
    border-radius: 0px;
}
.sidebar-panel .accordion .card .card-header{
    padding: 0;
    background: #ffffff;
    border: 0px;
}
.sidebar-panel .accordion .card .card-header h2 button{
    background: #ffffff;
    max-width: 100%;
    padding: 10px;
    overflow: hidden;
    height: 50px;
    display: flex;
    justify-content: stretch;
    align-items: center;
    text-decoration: none;
    border-radius: 0px;
    overflow-wrap: normal;
}
.sidebar-panel .accordion .card .card-header:hover h2 button{
    /* background: #ececec; */
    background: #f7f7f7;
}
.sidebar-panel .accordion .card .card-header h2 button:focus{
    box-shadow: none;
}
.sidebar-panel .accordion .card .card-header h2 button svg{
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    margin-left: 5px;
}
.sidebar-panel .accordion .card .card-header h2 button svg path{
    /* fill: #4583a2; */
    fill: rgba(57,109,213,.75);
}
.sidebar-panel .accordion .card .card-header h2 button span{
    position: relative;
    text-decoration: none;
    color: var(--primary-text-normal) !important;
    font-weight: 400;
    padding: 0 20px 0 40px;
    width: 100%;
    font-size: 17px;
    letter-spacing: 1px;
    min-width: 270px;
}
.sidebar-panel .accordion .card .card-header h2 button span a{
    color: var(--primary-text-normal) !important;
    text-decoration: none;
}
.sidebar-panel .accordion .card .card-header h2 button span svg{
    width: 10px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 2px;
    transform: rotate(180deg);
    transition: 0.3s all ease-in-out;
}
.sidebar-panel .accordion .card .card-header h2 button.collapsed span svg{
    transform: rotate(0deg);
    transition: 0.3s all ease-in-out;
}
.sidebar-panel .accordion .card .card-body{
    padding: 0px;
}
.sidebar-panel .accordion .card .card-body h6{
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 6px 0px 6px 60px;
    line-height: 0.8;
    color: var(--primary-text-normal) !important;
    transition: all 0.5s;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: -0.3px;
    text-decoration: none !important;
    margin-bottom: 0;
    min-width: 150px;
}
.sidebar-panel .accordion .card.child .card-body ul{
    margin-bottom: 15px!important;
}




.sidebar-panel:hover .accordion .card .card-body h6:before{
    position: absolute;
    content: none;
    left: 40px;
    top: 18px;
    width: 5px;
    height: 5px;
    background: rgba(57,109,213,.75);
    border-radius: 50px;
}
/* .sidebar-panel .accordion .card .card-body ul{
    background: #dadada;
} */
.sidebar-panel .accordion .card .card-body ul li{
    padding: 0px;
    font-size: 14px;
}

.sidebar-panel .accordion .card .card-body ul li a:before{
    color: #859eff;
    /* background: #4583a2; */
    /* background: #0081f2; */
    background: #f7f7f7;
    transition: all 0.5s;
    font-size: 13px;
    letter-spacing: 2px;
}

.sidebar-panel .accordion .card .card-body ul li a{
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5px 0px 5px 70px;
    /* background: #f7f7f7; */
    line-height: 1.5;
    color: var(--primary-text-normal) !important;
    transition: all 0.5s;
    text-decoration: none;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-decoration: none !important;
    min-width: 200px;
}

.sidebar-panel .accordion .card .card-body ul li a:hover{
    /* color: #ffffff; */
    /* background: #4583a2; */
    /* background: #0081f2; */
    background: #f7f7f7;
    transition: all 0.5s;
}

