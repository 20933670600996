.charting-tabs-view{
    position: relative;
}
.charting-tabs-view .charting-logo{
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    width: 100px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.charting-tabs-view .charting-logo small{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    color: #3d3d3d;
    margin: 5px auto;
}
.charting-tabs-view .nav-tabs{
    border: none;
    margin-bottom: 25px;
}
.charting-tabs-view .nav-tabs .nav-item button.nav-link{
    padding: 0;
    border: 0.25px solid var(--light-border-color);
    border-radius: 0px;
    margin-right: 15px;
    margin-bottom: 15px;
    height: 72px;
    min-width: 200px;
    box-shadow: 0 0 10px #f7f7f7c7;
}
.charting-tabs-view .nav-tabs .nav-item button.nav-link span{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 20px;
    background-color: #ffffff;
    width: 100%;
    color: #484848;
    font-size: 18px;
    font-weight: 400;
}
.charting-tabs-view .nav-tabs .nav-item button.nav-link span img{
    margin-right: 15px;
}
.charting-tabs-view .nav-tabs .nav-item button.nav-link.active span{
    background-color: #F5F5F5;
}