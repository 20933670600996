.sector-data-block .column-chart-block{
    font-size: 18px;
    border: 0.25px solid var(--light-border-color);
    border-radius: 5px;
    margin: 10px auto 0;
    width: 100%;
    display: inline-block;
}
.sector-data-block .column-chart-block .title-block h4{
    color: var(--table-heading-block-color);
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid var(--sharp-border-color);
    padding: var(--table-heading-block-inner-space);
    margin: 0;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-right: 70px;
}
.sector-data-block .column-chart-block .title-block h4 ul{
    position: absolute;
    right: 5px;
    top: 6px;
}
.sector-data-block .column-chart-block .title-block h4 ul li{
    padding: 0 5px;
}
.sector-data-block .column-chart-block .title-block h4 ul li span{
    cursor: pointer;
}
.sector-data-block .column-chart-block .title-block h4 ul li span img{
    width: 12px;
}
.sector-data-block .column-chart-block .data-block .column-chart{
    min-height: 350px;
    width: 100%; 
}
.sector-data-block .column-chart-block .data-block .column-chart canvas{
    font-size: 10px;
}