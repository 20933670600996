.analyst-panel-dashboard .ck-content {
    height: 400px;
}

.analyst-panel-dashboard table {
    border: 0.25px solid #3969D858;
    box-sizing: border-box;
    border-radius: 2px;
}

.analyst-panel-dashboard th {
    border: 0.25px solid #3969d56b;
    
}

.analyst-panel-dashboard table thead th {
    width: 73px;
    height: 14px;
    position: sticky;
    z-index: 1;
    /* font-family: 'Lato'; */
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #3D3D3D;
    border: 0.25px solid var(--light-border-color);
    /* border-bottom: 1.5px solid #3969D585 !important; */
    padding: 15px 14px;
}

.analyst-panel-dashboard table thead th:first-child {
    /* position: sticky; */
}

.analyst-panel-dashboard table thead .label-column-headers {
    position: sticky;
    top: 1px;
    left: 1px;
    background: white;
    z-index: 99999;
}

.analyst-panel-dashboard table .label-column-cells {
    text-align: left;
    position: sticky;
    top: 1px;
    left: 1px;
    background: white;
}

.financial-data-sheet{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
}
.financial-data-sheet table.labels-table{
    max-width: 450px;
}
.financial-data-sheet table.data-table{
    max-width: calc(100% - 450px);
    overflow-x: scroll;
    display: inline-block;
}
.financial-data-sheet table.data-table tbody tr td:last-child{
    min-width: 100px;
}
.financial-data-sheet table.data-table tbody tr td:last-child input{
    text-align: center;
}

.analyst-panel-dashboard table thead tr th:first-child {
    left: 0px;
    min-width: 100px;
    border-bottom: 1.5px solid var(--light-border-color);
    /* border-bottom: 1.5px solid #3969D585 !important; */
    padding: 0;
    overflow: hidden;
    background-color: white;
}

.analyst-panel-dashboard table thead tr th:nth-child(2) {
    left: 98px;
    min-width: 100px;
    overflow: hidden;
    border-bottom: 1.5px solid var(--light-border-color);
    /* border-bottom: 1.5px solid #3969D585 !important; */
    padding: 0;
    background-color: white;
}

.analyst-panel-dashboard table thead tr th:nth-child(3) {
    left: 196px;
    border-bottom: 1.5px solid var(--light-border-color);
    /* border-bottom: 1.5px solid #3969D585 !important; */
    padding: 0;
    overflow: hidden;
    background-color: white;
}

.analyst-panel-dashboard table tbody tr td:first-child {
    left: 0px;
    overflow: hidden;
    min-width: 100px;
    /* border-right: 1px solid var(--light-border-color); */
    padding: 0;
}

.analyst-panel-dashboard table tbody tr td:nth-child(2) {
    left: 98px;
    min-width: 100px;
    text-align: center;
    /* border-right: 1px solid var(--light-border-color); */
    padding: 0;
    overflow: hidden;
}

.analyst-panel-dashboard table tbody tr td:nth-child(3) {
    left: 196px;
    /* border-right: 1px solid var(--light-border-color); */
    padding: 0;
    overflow: hidden;
}


.analyst-panel-dashboard table td {
   /* font-family: 'Lato'; */
    font-style: normal;
    /* font-weight: 400; */
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #3D3D3D;
    border: 0.25px solid var(--light-border-color);
    padding: 6px;
    white-space: nowrap;
}

.topbar-tabs-wrapper{
    /* margin-left: -47px;
    margin-right: -50px */
    padding: 0 30px;
}

.topbar-tabs-wrapper .topbar-tabs {
    border-bottom: 1.5px solid #3969d56b;
    display: flex;
    justify-content: start;
}

.topbar-tabs-wrapper .topbar-tabs .nav-item button.nav-link{
    padding-bottom: 5px;
}

/* .col-12{
    padding: 30px;
} */

.topbar-tabs-wrapper .topbar-tabs .topbar-tabs-items {
    background-color: blue;
}

.topbar-tabs-wrapper .topbar-tabs.nav-tabs .nav-item{
    margin-right: 50px;
}

.topbar-tabs-wrapper .topbar-tabs.nav-tabs .nav-link.active {
    background-color: none;
    /* border: none; */
}

.topbar-tabs-wrapper .nav-tabs .nav-link {
    background: none;
    margin-bottom: -1px;
    font-size: 15px;
    color: #3d3d3d;
}

.topbar-tabs-wrapper .nav-tabs .nav-link.active {
    border: none;
    position: relative;
    border-bottom: 2px solid #3969D5;
    color: #3969D5;
}

.css-1okebmr-indicatorSeparator{
    display: none;
}

.css-14el2xx-placeholder ,  .css-qc6sy-singleValue, .search-main .search-field input::placeholder{
    font-size: 14px;
    color: #959595!important;
}

.company-information-tabs .nav-tabs {
    display: flex;
    justify-content: stretch;
}

.company-information-tabs .nav-tabs .nav-item {
    flex: 1;
    display: flex;
    justify-content: center;
}

.company-information-tabs .nav-tabs .nav-item button {
    width: 99%;
    background-color: #95959533;
    /* border-bottom: 0.25px solid #3969D5; */
    border: none;
    border-radius: 4px;
}

.company-information-tabs .nav-tabs .nav-link.active {
    width: 100%;
    background-color: white;
    font-weight: bold;
    color: #3969D5;
    font-size: 16px;
    border: 0.25px solid var(--light-border-color);
    border-bottom: none;
    border-radius: 0px;
}


.analyst-panel-dashboard #topbar-tabs-tabpane-company_introduction , .analyst-panel-dashboard #topbar-tabs-tabpane-revenue_drivers, .analyst-panel-dashboard #topbar-tabs-tabpane-cost_drivers{
    position: relative;
}
.analyst-panel-dashboard .company-information-tabs .ck-editor .ck-sticky-panel .ck-toolbar{
    background-color: #ffffff;
    border: 0.25px solid var(--light-border-color);
    border-top: 0px;
}
.analyst-panel-dashboard .company-information-tabs .ck-editor .ck-editor__main{
    border: 0.25px solid var(--light-border-color);    
}
.analyst-panel-dashboard .company-information-tabs .ck-editor .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border: none;
    padding: 0 20px;
}

#topbar-tabs-tabpane-recent-development{
    position: relative;
}
#topbar-tabs-tabpane-recent-development .recent-notification-block{
    height: 40px;
    width: 60px;
    right: 0;
    top: -40px;
}
#topbar-tabs-tabpane-recent-development .recent-notification-block .main-blinker{
    top: 0px;
}
#topbar-tabs-tabpane-recent-development .recent-notification-block .main-blinker ul{
    width: 270px;
}
#topbar-tabs-tabpane-recent-development .recent-notification-block .main-blinker.hide ul{
    width: 0px;
}
/* #topbar-tabs-tabpane-recent-development .btn.btn-primary{
    min-height: 38px;
} */
#topbar-tabs-tabpane-recent-development .ck-editor .ck-sticky-panel .ck-toolbar{
    background-color: #ffffff;
    border: 0.25px solid var(--light-border-color);
}
#topbar-tabs-tabpane-recent-development .ck-editor .ck-editor__main{
    border: 0.25px solid var(--light-border-color); 
}
#topbar-tabs-tabpane-recent-development .ck-editor .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
    border: none;
}

.analyst-panel-dashboard .company-information-tabs  .ck-word-count {}
.analyst-panel-dashboard .company-information-tabs  .ck-word-count__words {}
.analyst-panel-dashboard .company-information-tabs  .ck-word-count__characters {}


#topbar-tabs-tabpane-recent-development .fields-instance{
    position: relative;
}
#topbar-tabs-tabpane-recent-development .fields-instance .remove-instance{
    position: absolute;
    top: -5px;
    right: 10px;
    width: 15px;
    height: 15px;
    background-color: #3969D5;
    border-radius: 50%;
    color: #ffffff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
}

#topbar-tabs-tabpane-recent-development .recent-development-form{
    padding: 5px 10px;
    /* background: #f9fbff; */
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 10px;
    /* box-shadow: 0 0 10px #d9d9d9; */
    box-shadow: 0 0 10px #fbfbfb;
}
#topbar-tabs-tabpane-recent-development .recent-development-form .form-group{
    margin: 5px 0;
}
#topbar-tabs-tabpane-recent-development .recent-development-form .form-group.primary-select.no-label::after{
    z-index: 9999;
}
#topbar-tabs-tabpane-recent-development .recent-development-form label{
    position: relative;
    width: 100%;
    color: var(--primary-text-normal);
}
#topbar-tabs-tabpane-recent-development .recent-development-form .css-b62m3t-container:focus{
    border: none;
}
#topbar-tabs-tabpane-recent-development .recent-development-form .news-add{
    /* width: calc(100% - 100px); */
    width: 100%;
}
#topbar-tabs-tabpane-recent-development .recent-development-form .news-add input{
    color: #969697;
    border: var(--light-border);
}
#topbar-tabs-tabpane-recent-development .recent-development-form .news-add input::placeholder{
    color: #969697;
}
#topbar-tabs-tabpane-recent-development .recent-development-form .news-add-btn{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
#topbar-tabs-tabpane-recent-development .recent-development-form .news-add-btn button{
    margin-top: 15px;
}
#topbar-tabs-tabpane-recent-development .add-from-instance{
    color: #3969D5;
    line-height: 1;
    height: 25px;
    padding: 0 15px;
    box-shadow: none;
    text-decoration: none;
}
#topbar-tabs-tabpane-recent-development .add-from-instance:focus{
    text-decoration: none;
    box-shadow: none;
}
#topbar-tabs-tabpane-recent-development .save-all , #topbar-tabs-tabpane-recent-development .draft-all{
    padding: 0 30px;
}
#topbar-tabs-tabpane-company-information .container-fluid{
    position: relative;
}