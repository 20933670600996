.period-frequency-block{
    /* margin-top: 15px; */
    padding: 10px 0;
    border-top: 0.25px solid var(--light-border-color);
}
.period-frequency-block .form-group.field-wrap label{
    color: var(--primary-text-dark);
}
.period-frequency-block .form-group.field-wrap .css-1s2u09g-control , .period-frequency-block .form-group.field-wrap .form-control{
    border: 0.25px solid var(--light-border-color);
    border-radius: 5px;
    width: 250px;
    min-height: 35px;
    max-height: 35px;
}