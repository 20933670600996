.policy-sop-main{
    width: 100%;
    flex-direction: column;
    padding: 20px 30px;
    font-family: 'lato', sans-serif;
}
.policy-sop-main .add-policy-filter{
    margin: 0 0 10px;
    justify-content: flex-end;
}
.policy-sop-main .add-policy-filter button{
    font-size: 14px;
    font-weight: 400;
    height: 35px;
}
.policy-sop-main .add-policy-filter button.add-policy{
    background-color: #3969D5;
}
.policy-sop-main .policy-sop-sec{
    min-width: 100%;
    /* border: 0.25px solid var(--keyupdate-border-color); */
    margin: 0;
    max-height: initial;
    padding: 0;
}
.policy-sop-main .policy-sop-sec .title{
    border-bottom: 1px solid var(--sharp-border-color);
    /* color: var(--table-heading-block-color); */
    color: var(--primary-text-normal);
    font-size: var(--table-heading-block-font-size);
    font-weight: var(--table-heading-block-weight);
    margin: 0;
    padding: var(--table-heading-block-inner-space);
    text-transform: capitalize;
    border: 0.25px solid var(--keyupdate-border-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.policy-sop-main .policy-sop-sec .policy-list-sec .policy-list-block{
    margin-top: 20px;
    max-width: 350px;
}
.policy-sop-main .policy-sop-sec .policy-list-sec .policy-list-block h3{
    font-size: 14px;
    font-weight: var(--table-heading-block-weight);
    color: var(--table-heading-block-color);
    padding: var(--table-heading-block-inner-space);
    border-bottom: var(--sharp-border);
}
.policy-sop-main .policy-sop-sec .policy-list-sec .policy-list-block ul li{
    padding-left: 12px;
}
.policy-sop-main .policy-sop-sec .policy-list-sec .policy-list-block h4{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
    border-bottom: var(--light-border);
}
.policy-sop-main .policy-sop-sec .policy-list-sec .policy-list-block h4 span{
    margin-right: 10px;
}
.policy-sop-main .policy-sop-sec .policy-list-sec .policy-list-block h4 span img{
    width: 20px;
}
.policy-sop-main .policy-sop-sec .policy-list-sec .policy-list-block ul li h4 a{
    text-decoration: none;
    font-size: 12px;
    color: var(--primary-normal);
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.upload-file-modal .modal-content{
    border-radius: 25px;
    border: none;
    padding: 30px;
}
.upload-file-modal .modal-content .modal-header{
    border: none;
    padding: 0;
    margin-bottom: 25px;
}
.upload-file-modal .modal-content .modal-body{
    padding: 0;
}
.upload-file-modal label{
    font-size: 14px;
    color: #000;
    font-weight: 400;
}
.upload-file-modal input{
    text-align: left;
}
.upload-file-modal button.btn-primary{
    background-color: var(--primary-color);
}
.upload-file-modal button.btn-cancel{
    border: 0.25px solid var(--light-border-color);
    background-color: #ffffff!important;
    color: #212529;
}
.upload-file-modal input::placeholder{
    text-align: left;
}
.upload-file-modal .upload-btn-block{
    margin-top: 30px;
}
.upload-file-modal .dropzone-main{
    width: calc(100% - 4px);
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgb(57 109 213 / 30%);
    margin-top: 30px;
    border-radius: 6px;
    flex-direction: column;
    padding: 30px 10px;
}
.upload-file-modal .dropzone-main div > p{
    margin: 0;
    color: #464646;
}
.upload-file-modal .dropzone-main ul li{
    display: inline-block;
    background-color: #3969D5;
    color: #ffffff;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 5px;
    margin: 5px auto;
    font-weight: 300;
    font-size: 12px;    
}
.upload-file-modal .dropzone-main .uploaded-files{
    display: inline-block;
    background-color: #3969D5;
    color: #ffffff;
    text-transform: capitalize;
    border-radius: 5px;
    padding: 5px;
    margin: 5px auto;
    font-weight: 300;
    font-size: 12px;
}
.upload-file-modal .dropzone-main .dropzone-text-block{
    color: #adadad;
}
.upload-file-modal .dropzone-main .dropzone-text-block .drop-text{
    color: var(--primary-text-normal);
    font-weight: 700;
    font-size: 18px;
}
.upload-file-modal .dropzone-main .dropzone-text-block .browse-text{
    display: inline-block;
    color: #3969D5;
    text-transform: capitalize;
    border-radius: 5px;
    margin: 5px auto;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    cursor: pointer;
}
