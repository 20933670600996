.analyst-panel-dashboard
.registered-user-table .gzECfY {
    border-bottom: 0.25px solid var(--light-border-color);
    padding: 0px 15px    
}

.analyst-panel-dashboard
.registered-user-table .gzECfY .form-control {
    font-size: 12px;
    border: 0.25px solid var(--light-border-color);
    color: #3d3d3d;
}

.analyst-panel-dashboard
.registered-user-table .gzECfY .form-control::placeholder{
    color: #3d3d3d;
}


.analyst-panel-dashboard
.registered-user-table .card-header:first-child{
    padding: 7px 14px;
    background-color: white !important;   
    border-bottom: 1px solid var(--light-border-color);
}

.analyst-panel-dashboard
.registered-user-table .card-header:first-child h4 {
    font-size: 16px;
    color: var(--table-heading-block-color);
    font-weight: var(--table-heading-block-weight);
    margin: 0;
}

.analyst-panel-dashboard
.registered-user-table .card {
    border: 0.25px solid var(--light-border-color);
}

.analyst-panel-dashboard
.registered-user-table .card .card-body {
    padding: 0 !important;
}

.analyst-panel-dashboard .registered-user-table .rdt_TableHeadRow .rdt_TableCol span.font-bold{
    font-size: 14px;
    color: #3d3d3d;
    text-transform: capitalize;
    font-weight: 400;
}

.analyst-panel-dashboard
.registered-user-table .rdt_TableRow {
    border-bottom: 0.25px solid var(--light-border-color);
    min-height: 35px;
}

.analyst-panel-dashboard
.registered-user-table .rdt_TableRow > div > div{
    font-size: 12px;
}

.analyst-panel-dashboard
.registered-user-table .rdt_TableCol,
.analyst-panel-dashboard
.registered-user-table .rdt_TableCell {
    display: flex;
    justify-content: center;
}

.sc-gsDKAQ.fbhklE.rdt_TableHead{
    border-bottom: 1px solid #3969D585;
    font-size: 16px;
}

.sc-jrQzAO.fhCFWO.rdt_TableRow{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #3D3D3D;
}


.sc-dkPtRN.hCBnvI.rdt_TableHeadRow .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol:first-child{
    justify-content: left;
}

.sc-dkPtRN.hCBnvI.rdt_TableHeadRow .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol{
    justify-content: center;
}

.sc-jrQzAO.fhCFWO.rdt_TableRow .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell:first-child{
    justify-content: left;
}

.sc-jrQzAO.fhCFWO.rdt_TableRow .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell{
    justify-content: center;
}