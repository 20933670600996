.wi-33{
    width: calc(33.3333% - 4px)!important;
}
.wi-35{
    width: calc(35% - 4px)!important;
}
.wi-40{
    width: calc(40% - 4px)!important;
}
.wi-45{
    width: calc(45% - 4px)!important;
}
.wi-50{
    width: calc(50% - 4px)!important;
}
.wi-55{
    width: calc(55% - 4px)!important;
}
.wi-60{
    width: calc(60% - 4px)!important;
}
.wi-65{
    width: calc(65% - 4px)!important;
}
.wi-100{
    width: calc(100% - 4px)!important;
}
.he-10{
    height: calc(10% - 4px)!important;
}
.he-15{
    height: calc(15% - 4px)!important;
}
.he-40{
    height: calc(40% - 4px)!important;
}
.he-45{
    height: calc(45% - 4px)!important;
}
.he-85{
    height: calc(85% - 4px)!important;
}
.he-100{
    height: calc(100% - 4px)!important;
}
.h2-10{
    height: calc(10%)!important;
}
.h2-15{
    height: calc(15%)!important;
}
.h2-40{
    height: calc(40%)!important;
}
.h2-45{
    height: calc(45%)!important;
}
.h2-50{
    height: calc(50%)!important;
}
.h2-85{
    height: calc(85%)!important;
}
.h2-100{
    height: calc(100%)!important;
}
.w2-33{
    width: calc(33.3333%)!important;
}
.w2-35{
    width: calc(35%)!important;
}
.w2-40{
    width: calc(40%)!important;
}
.w2-45{
    width: calc(45%)!important;
}
.w2-50{
    width: calc(50%)!important;
}
.w2-55{
    width: calc(55%)!important;
}
.w2-60{
    width: calc(60%)!important;
}
.w2-65{
    width: calc(65%)!important;
}
.w2-100{
    width: calc(100%)!important;
}
.mxy-3{
    margin: 2px;
}
.p-bg{
    background: #ececec;
    border-radius: 5px;
}




.c-temp-list{
    overflow-x: auto;
    display: inline-flex;
    height: 170px;
}
.c-temp-list::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
.c-temp-list::-webkit-scrollbar{
    width: 2px;
    height: 4px;
    background-color: #F5F5F5;
}
.c-temp-list::-webkit-scrollbar-thumb{
    /* background-color: #305e85; */
    background: rgb(0 129 242);
}
.c-temp-list .c-temp-item{
    min-width: 140px;
    width: 140px;
    height: 140px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    padding: 1px;
    box-shadow: 0 0 10px #dcd9d9;
}
.c-temp-list .c-temp-item::before{
    position: absolute;
    content: '';
    background: #000000;
    opacity: 0.5;
    z-index: 99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
}
.c-temp-list .c-temp-item:hover::before{
    visibility: visible;
}
.c-temp-list .c-temp-item .temp-head{
    text-align: center;
    font-size: 10px;
    margin: 0;
    padding: 2px 0;
    line-height: 1;
    width: 100%;
    /* background: #ececec; */
    height: 25px;
}
.c-temp-list .c-temp-item .temp-head h6{
    font-size: 12px;
    margin: 0;
    padding: 3px 0;
}
.c-temp-list .c-temp-item .temp-content{
    background: #0081f2;
    height: calc(100% - 50px);
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
}
.c-temp-list .c-temp-item .temp-content ul li{
    width: 20px;
    height: 20px;
    margin: 0 5px;
}
.c-temp-list .c-temp-item .add-template{
    display: none;
    position: absolute;
    content: '';
    z-index: 999;
    border: none;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 5px;
    font-weight: 600;
    visibility: hidden;
    background: #0081f27d;
    color: #ffffff;
    font-size: 25px;
}
.c-temp-list .c-temp-item:hover .add-template{
    visibility: visible;
}
.c-temp-list .c-temp-item .temp-foot{
    text-align: center;
    font-size: 10px;
    margin: 0;
    padding: 2px 0;
    line-height: 1;
    width: 100%;
    /* background: #ececec; */
    height: 25px;
}
.c-temp-list .c-temp-item .temp-foot h6{
    font-size: 12px;
    margin: 0;
    padding: 3px 0;
}
