.fields-block .filter-form-block{
    position: relative;
    display: flex;
    flex-wrap: wrap;    
}
.fields-block .filter-form-block .blur{
    position: absolute;
    left: 0;
    right: 15px;
    top: 0;
    bottom: 0;
    background: #ececec80;
    z-index: 99;
    border-radius: 5px;
}
.fields-block .data-series-selected{
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.fields-block .data-series-selected h3{
    font-weight: 600;
    font-size: 14px;
    color: #484848;
}
.fields-block .data-series-selected .data-series-list-block{
    position: relative;
    border: 0.25px solid var(--light-border-color);
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    min-height: calc(100% - 35px);
    padding: 5px;
    min-height: 110px;
    max-height: 150px;
    overflow-y: auto;
}
.fields-block .data-series-selected .data-series-list-block .blur{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ececec80;
    z-index: 99;
}
.fields-block .data-series-selected  .data-series-list-block ul{
    width: 100%;
    display: inline-block;
    margin: 0;
    /* margin: 5px auto; */
    /* border-bottom: 0.25px solid #ececec; */
    height: fit-content;
}
.fields-block .data-series-selected ul li{
    display: inline-block;
    height: fit-content;
}
.fields-block .data-series-selected ul li .data-selected-item{
    position: relative;
    color: #484848;
    font-size: 12px;
    font-weight: 400;
    margin: 0 5px;
}
.fields-block .data-series-selected ul li .data-selected-item span{
    position: absolute;
    content: '';
    right: -7px;
    top: 0;
    background-color: #484848;
    color: #ffffff;
    font-size: 7px;
    font-weight: 400;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.sector-data-block{
    padding: 35px 0;
    position: relative;
}
.sector-data-block .chart-select{
    display: inline-flex;
    position: relative;
    width: 100%;
    justify-content: flex-end;
}
.sector-data-block .chart-select ul{
    position: absolute;
    right: 0;
    width: 90px;
    text-align: center;
    box-shadow: 0 0 10px #ececec;
    background: #e0eaff;
    border-radius: 5px;
    top: 25px;
    font-size: 14px;
    z-index: 9999;
    display: none;
}
.sector-data-block .chart-select:hover ul{
    display: block;
}
.sector-data-block .chart-select ul li{
    width: 100%;
    display: inline-block;
}
.sector-data-block .chart-select ul li:not(:last-child){
    border-bottom: 1px solid var(--light-border-color);
}
.sector-data-block .chart-select ul li span{
    cursor: pointer;
    width: 100%;
}
.sector-data-block .btn.btn-chart{
    padding: 0 5px;
    border: none;
    color: #3d3d3d;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #3d3d3d;
    height: auto;
}
.sector-data-block .btn.btn-chart:focus{
    box-shadow: none;
}
.sector-data-block .btn.btn-chart span{
    display: inline-block;
    position: relative;
}
.sector-data-block .btn.btn-chart.active span::before{
    position: absolute;
    content: '';
    height: 2px;
    width: 60%;
    background-color: #3d3d3d;
    left: 6px;
    right: 0;
    top: 45%;
    bottom: 0;
    transform: rotate(-45deg);
}
.sector-data-block .btn.btn-chart span img{
    display: inline-block;
    width: 30px;
    height: 20px;
    position: relative;
    top: -2px;
}
