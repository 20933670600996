.analyst-panel-dashboard
.manage-user-table header {
    /* border-bottom: 0.25px solid var(--light-border-color);
    padding: 0px 15px     */
    border-bottom: none;
    padding: 0px;
    position: absolute;
    top: 5px;
    display: inline-block;
    right: 5px;
    width: auto;
    min-height: 30px;
}

.analyst-panel-dashboard
.manage-user-table .gzECfY .form-control {
    font-size: 12px;
    border: 0.25px solid var(--light-border-color);
    color: #3d3d3d;
}

.analyst-panel-dashboard
.manage-user-table .gzECfY .form-control::placeholder{
    color: #3d3d3d;
}


.analyst-panel-dashboard
.manage-user-table .card-header:first-child{
    padding: 10px 14px;
    background-color: white !important;   
    border-bottom: 1px solid var(--light-border-color);
}

.analyst-panel-dashboard
.manage-user-table .card-header:first-child h4 {
    font-size: 16px;
    color: var(--table-heading-block-color);
    font-weight: var(--table-heading-block-weight);
    margin: 0;
}

.analyst-panel-dashboard
.manage-user-table .card {
    border: 0.25px solid var(--light-border-color);
}

.analyst-panel-dashboard
.manage-user-table .card .card-body {
    padding: 0 !important;
}

.analyst-panel-dashboard .manage-user-table .rdt_TableHeadRow .rdt_TableCol span.font-bold{
    font-size: 12px;
    color: #3d3d3d;
    text-transform: capitalize;
    font-weight: 400;
}
.analyst-panel-dashboard .manage-user-table .rdt_TableHeadRow{
    min-height: 35px;
}

.analyst-panel-dashboard .manage-user-table .rdt_TableRow {
    border-bottom: 0.25px solid var(--light-border-color);
    min-height: 35px;
}

.analyst-panel-dashboard .manage-user-table .rdt_TableRow .company-list ul li span{
    background-color: #ececec;
    font-size: 11px;
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
    text-transform: capitalize; 
}
.analyst-panel-dashboard
.manage-user-table .rdt_TableCol,
.analyst-panel-dashboard
.manage-user-table .rdt_TableCell {
    display: flex;
    justify-content: left!important;
}
.analyst-panel-dashboard .manage-user-table .assign-form-btn button:hover , 
.analyst-panel-dashboard .manage-user-table .rdt_TableCell button:hover{
    box-shadow: 0 0 10px #ececec !important;
    transform: scale(1.01);
    transition: 0.1s all ease-in;
}
.sc-gsDKAQ.fbhklE.rdt_TableHead{
    border-bottom: 1px solid var(--sharp-border-color)!important;
    font-size: 16px;
}

.sc-jrQzAO.fhCFWO.rdt_TableRow{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #3D3D3D;
}
.sc-dkPtRN.hCBnvI.rdt_TableHeadRow .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol:first-child{
    justify-content: left;
}
.sc-dkPtRN.hCBnvI.rdt_TableHeadRow .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol{
    justify-content: left !important;
}
.sc-jrQzAO.fhCFWO.rdt_TableRow .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell{
    justify-content: left !important;
}
.sc-jrQzAO.fhCFWO.rdt_TableRow .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell:first-child{
    justify-content: left;
}
.analyst-panel-dashboard
.manage-user-table .rdt_TableCol:last-child , 
.analyst-panel-dashboard
.manage-user-table .rdt_TableCell:last-child{
    justify-content: center!important;
}
.rdt_TableCol_Sortable > span:last-child{
    opacity: 1;
    color: #3d3d3d;
}
.analyst-panel-dashboard
.manage-user-table nav.rdt_Pagination{
    border-top: none;
}
.analyst-panel-dashboard
.manage-user-table .edit-action{
    cursor: pointer;
}