.draft-sidebar {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 20px 15px;
  box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 23%);
  -webkit-box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 23%);
  -moz-box-shadow: 1px 3px 10px 1px rgba(0, 0, 0, 0.23);
  width: 450px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  position: absolute;
  right: -40px;
  bottom: 0;
  top: 0;
  z-index: 999999;
  background-color: white;
  transition: all 0.5s ease-in-out;
}

.draft-sidebar.draft-sidebar-active {
  width: 0px;
  padding: 10px 0;
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
}

.draft-sidebar > div {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.draft-sidebar ul {
  list-style: none;
}

.draft-sidebar ul li{
  border-bottom: 1px solid #dbdbdb;
  margin: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 0;
}

.draft-sidebar ul li .btn {
  text-align: left;
  font-weight: bold;
  color: #3969d5;
  display: inline-block;
  padding: 0;
  line-height: 1;
  height: auto;
}

.draft-sidebar ul li .draft-meta{
  line-height: 1;
  margin-bottom: 10px;
}
.draft-sidebar ul li .draft-meta span{
  color: #3d3d3d;
  font-size: 12px;
  text-transform: capitalize;
}
.draft-sidebar ul li .draft-meta span.date{
  margin-right: 10px;
}
.draft-sidebar ul li span.reject-label{
  padding: 5px 10px;
  font-size: 10px;
  line-height: 1;
  background: #ffa2a2;
  font-weight: 400;
  color: #ffffff;
  border-radius: 15px;
}

.draft-sidebar-close-btn {
  background: #ff000017;
  font-weight: bold;
  color: red;
  height: 35px;
  width: 35px;
  border-radius: 25px;
  position: absolute;
  top: 10px;
  left: 15px;
}

.slide {
  position: absolute;
  left: -600px;
  width: 600px;
  height: 370px;
  background: blue;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 2s;
  animation: slide 0.5s forwards;
  animation-delay: 2s;
}

.draft-btn {
  cursor: pointer;
}

.draft-sidebar-loader {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 50px;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}
