/* :root {
    --primary-color: #3969D5;
    --primary-border-color: #3969d56b;
  } */

:root {
    --primary-color: #3969D5;
    --primary-normal: #3969d5c7;
    --primary-text-dark: #3d3d3d;
    --primary-text-normal: #080404ab;
    --primary-text-light: #3d3d3dab;
    --light-black: #484848e8;
    --white: #ffffff;
    --title-bottom-border: #396dd575;
    --title-color: #484848e8;
    --title-fontSize: 16px;
    --title-weight: 600;
    --title-padding: 8px 10px;
    --sharp-border: 1px solid rgb(57 109 213);
    --light-border: 0.25px solid rgb(57 109 213 / 30%);
    --light-border-color: rgb(57 109 213 / 20%);
    --sharp-border-color: #396dd575;

    --keyupdate-border-color: rgba(57, 109, 213, 20%);
    --keyupdate-date-color: #a0a7bc;

    --light-gray: #F7F7F7;

    --table-heading-m: 12px;
    /* --table-heading-inner-space: 12px 8px; */
    --table-heading-inner-space: 6px;
    --table-heading-weight: 600;
    --table-heading-color: #484848e8;
    /* --table-heading-color: #3d3d3d; */
    /* --table-heading-font-size: 15px; */
    --table-heading-font-size: 12px;
    --table-heading-text-align: right;


    /* --table-cell-inner-space: 6px 8px; */
    --table-cell-inner-space: 6px;
    --table-cell-color: #484848e8;
    /* --table-cell-color: #3d3d3d; */
    --table-cell-weight: 600;
    --table-cell-text-align: right;
    --table-cell-font-size: 11px;

    --table-label-column-color: #484848e8;
    --table-label-column-weight: 500;
    --table-label-column-text-align: left;
    --table-label-column-font-size: 12px;
    --table-label-column-inner-spacing: 10px 7px;

    --table-heading-block-m: 12px;
    /* --table-heading-block-inner-space: 12px 8px; */
    --table-heading-block-inner-space: 7px;
    --table-heading-block-weight: 600;
    --table-heading-block-color: #484848e8;
    /* --table-heading-block-color: #3d3d3d; */
    --table-heading-block-font-size: 16px;
    --table-heading-block-text-align: right;

    --td-inner-spacing: 7px 10px;
    --charts-block-shadow: 0 0 10px #67b7dc63;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;

}

input[type=number] {
    -moz-appearance: textfield;
}

.validation-failed {
  border-bottom: 3px solid red !important;
}

*::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

*::-webkit-scrollbar {
    width: 2px;
    height: 4px;
    background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
    background-color: #3969D5;
}

#root {
    min-height: 100vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#root .App {
    width: 100%;
}

#root>.dashboard {
    min-width: 100%;
}

* {
    font-family: 'IBM Plex Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Lato', sans-serif;
}

/* .text-color{
  color: var(--primary-text) !important;
} */

.modal {
    z-index: 9999999;
}

.modal-backdrop {
    z-index: 9999999;
}

.modal-dialog .modal-header {
  padding: 15px 15px 10px;
  border-bottom:  1px solid var(--light-border-color) !important;
}

.modal-dialog .modal-header .modal-title {
    font-size: 18px;
    color: var(--primary-text-normal);
    line-height: 1;
    font-weight: 600;
}

.modal-dialog .modal-content{
  border: none;
  border-radius: 10px;
}

/* ===================================== */
/*          FULL SCREEN LOADER           */
/* ===================================== */

.full-screen-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ===================================== */
/*            BUTTONS                    */
/* ===================================== */

.btn-primary {
  background-color: #3969d5;
}

/* ===================================== */
/*            TYPOGRAPOHY */
/* ===================================== */

.theme-primary-text {
  color: var(--primary-color);
  margin-left: 20px;
}

.text-align-left {
  text-align: left !important;
}

.text-align-center {
  text-align: center !important;
}

.font-bold {
  font-weight: bold;
}

.css-1s2u09g-control {
    border: 0.25px solid var(--light-border-color) !important;
    height: 35px;
    min-height: 35px !important;
}

blockquote::before {
  content: "\201C";
}

blockquote::after {
  content: "\201D";
}

.edit-action {
    border: 1.25px solid #3969d585;
    padding: 3px;
    border-radius: 3px;
}

.btn {
    font-size: 12px;
    font-weight: 400;
    height: 32px;
}

.primary-select select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    position: relative;
    padding-right: 30px;
    border: var(--light-border);
}

.primary-select select:focus{
  box-shadow: none;
}
.primary-select select option{
  font-size: 14px;
  padding: 5px;
  font-size: var(--primary-text-normal);
}
.primary-select select option:hover, .primary-select select option:checked{  
  color: #ffffff;
  background-color: var(--primary-color);
  -webkit-color: #ffffff;
  -webkit-background-color: var(--primary-color);
}


.primary-select label , .primary-input label {
    position: relative;
    width: 100%;
    color: var(--primary-text-normal);
}

.primary-select label::after {
    content: '>';
    font: 17px "Consolas", monospace;
    color: var(--light-black);
    transform: rotate(90deg);
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    z-index: 999999;
    bottom: -30px;
    right: 17px;
}
.primary-select.no-label{
  position: relative;
}
.primary-select.no-label::after {
  content: '>';
  font: 17px "Consolas", monospace;
  color: var(--light-black);
  transform: rotate(90deg);
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 999999;
  bottom: 5px;
  right: 10px;
}
.primary-select.no-label span.field-error{
  position: absolute;
}

.primary-select span {
  position: relative;
  width: 100%;
  display: inherit;
  z-index: 0;
}

.primary-select span::after {
  content: '>';
  font: 17px "Consolas", monospace;
  color: var(--light-black);
  transform: rotate(90deg);
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  z-index: 999999;
  bottom: 6px;
  right: 12px;
}
.primary-select span.field-error::after{
  display: none;
}


.primary-select select, .primary-input input {
    color: #adadad;
}

.btn-primary {
    background-color: var(--primary-color) !important;
    transition: 0.1s all ease-in;
}

.btn-primary:hover {
    transform: scale(1.01);
    transition: 0.1s all ease-in;
    box-shadow: 0 0 10px #c2d4ff !important;
}

.btn-primary:focus {
    box-shadow: none !important;
}

.btn-light {
    color: var(--primary-grey) !important;
    transition: 0.1s all ease-in;
    margin: 0 5px;
    padding: 5px 10px;
    height: 30px;
    font-size: 12px;
    display: inline-block;
}

.btn-light:hover {
    transform: scale(1.01);
    transition: 0.1s all ease-in;
    box-shadow: 0 0 10px #c2d4ff !important;
}

.btn-light:focus {
    box-shadow: none !important;
}

.btn-cancel {
    transition: 0.1s all ease-in;
    color: var(--primary-text-normal) !important;
}

.btn-success {
  transition: 0.1s all ease-in;
  color: #ffffff !important;
}

.btn-danger {
  transition: 0.1s all ease-in;
  color: #ffffff !important;
}

.btn-cancel:hover {
    box-shadow: 0 0 10px #ececec !important;
    transform: scale(1.01);
    transition: 0.1s all ease-in;
}

.form-control {
    font-size: 14px;
    font-weight: 400;
    min-height: 35px;
}

.btn-action {
  cursor: pointer;
  border: 1.25px solid #3969d585;
  padding: 3px;
  border-radius: 3px;
}
.brain-table-card{
  width: 100%;
  margin: 10px 0;
  padding: 0;
  border: 0.25px solid var(--light-border-color);
  border-radius: 4px;
  overflow: hidden;
}
.brain-table-card .brain-table-card-header{
  position: relative;
  /* margin: 10px; */
  padding: 35px 2px 0px;
  overflow: hidden;
  width: 100%;
  border-color: var(--light-border-color) !important;
}
.brain-table-card .brain-table-card-header h3{
  margin: 0;
  border-bottom: 0.25px solid var(--light-border-color);
  position: absolute;
  top: 0;
  padding: 7px 12px;
  left: 0;
  right: 0;
  font-size: var(--table-heading-block-font-size);
  text-transform: capitalize;
  font-weight: var(--table-heading-block-weight);
  color: var(--primary-text-normal);
}

.brain-table-card .rdt_TableCol:not(:first-child) , .brain-table-card .rdt_TableCell:not(:first-child) {
  justify-content: center;
}

.contact-list-sec .brain-table-card .rdt_TableCol:not(:first-child) , .contact-list-sec .brain-table-card .rdt_TableCell:not(:first-child) {
  justify-content: flex-start;
}
.contact-list-sec .brain-table-card .sc-dkPtRN.cpjJTk.rdt_TableHeadRow{
    min-height: 35px;
    border: none;
    border-bottom: var(--sharp-border);
}

.brain-table-card .rdt_TableCol , .brain-table-card .rdt_TableCell {
  color: var(--primary-text-normal) !important;
}

.brain-table-card .rdt_TableRow {
  font-size: 12px !important;
  min-height: 35px !important;
  /* max-height: 45px !important; */
}
.brain-table-card .rdt_TableRow:not(:last-child){
  border-bottom: 0.25px solid var(--light-border-color);
}
.brain-table-card .hCBnvI.rdt_TableHeadRow{
  min-height: 35px;
  border: none;
}

.brain-table-card .hLGqlg .sc-gsDKAQ.fbhklE.rdt_TableHead {
  font-size: var(--table-heading-block-font-size);
  /* border-bottom: 1px solid var(--sharp-border-color) !important ; */
  border-bottom: var(--sharp-border);
}

.brain-table-card .sc-dkPtRN.hCBnvI.rdt_TableHeadRow .sc-crHmcD.bNgcsh.rdt_TableCol_Sortable{
  color: var(--primary-grey);
  font-size: 14px;
}

.rdt_Pagination{
  border-top: 0.25px solid var(--light-border-color) !important;
}

.brain-table-card .sc-dkPtRN.hCBnvI.rdt_TableHeadRow
  .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol {
  justify-content: center !important;
}

.brain-table-card .rdt_TableHeadRow .rdt_TableCol > div{
  font-weight: 600;
}

.brain-table-card .sc-dkPtRN.hCBnvI.rdt_TableHeadRow
  .sc-hKwDye.sc-eCImPb.sc-iqseJM.bVYdcq.fvAeSn.iwmeYY.rdt_TableCol:first-child {
  justify-content: left !important;
  max-width: fit-content;
}

.brain-table-card .sc-jrQzAO.fhCFWO.rdt_TableRow
  .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell {
  justify-content: center !important;
  /* min-width: auto; */
}

.brain-table-card .sc-jrQzAO.fhCFWO.rdt_TableRow
  .sc-hKwDye.sc-eCImPb.sc-jRQBWg.kUKiTL.fvAeSn.bsgpsK.rdt_TableCell:first-child {
  justify-content: left !important;
  max-width: fit-content;
}

