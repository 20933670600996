.comp-financial-block{
    font-size: 18px;
    border-radius: 0px;
    margin: 10px auto 0;
    width: 100%;
    display: inline-block;
    position: relative;
}
.comp-financial-block .data-table-extensions{
    padding: 0;
    display: inline-flex;
    position: absolute;
    top: -35px;
    z-index: 999;
    left: 10px;
    width: 150px;
}
.comp-financial-block .data-table-extensions .data-table-extensions-filter{
    display: none;
}
.data-table-extensions > .data-table-extensions-action > button.print{
    display: none;
}
.data-table-extensions > .data-table-extensions-action > button.download{
    background-image: url('../../../../assets/media/downloadicon-blue.svg');
    background-size: contain;
    color: #3969d5;
    height: 20px;
    width: 20px;
}
.data-table-extensions > .data-table-extensions-action > button.download:hover{
    background-color: transparent;
}
.comp-financial-block .download-csv{
    position: absolute;
    top: -40px;
    padding: 0;
    background: transparent;
    border: none;
}
.comp-financial-block .download-csv img{
    width: 50px;
    height: 50px;
    object-fit: contain;
}
.comp-financial-block .title-block h4{
    color: var(--table-heading-block-color);
    font-size: var(--table-heading-block-font-size);
    font-weight: var(--table-heading-block-weight);
    border-bottom: 1px solid var(--sharp-border-color);
    padding: var(--table-heading-block-inner-space);
    margin: 0;
    text-transform: capitalize;
    border-left: none;
}
.comp-financial-block .data-block .comp-financials{
    width: 100%;
    overflow: auto;
    border: 0.25px solid var(--light-border-color);
}
.comp-financial-block .data-block table{
    margin: 0;
    display: inline-table;
    border-collapse: collapse;
    border: none;   
}
.comp-financial-block .data-block table thead tr th{
    border-top: 0px;
    border-right: 0.25px solid var(--light-border-color);
    border-collapse: collapse;  
    border-bottom: 1px solid var(--sharp-border-color) !important;
    color: var(--table-heading-color);
    font-size: var(--table-heading-font-size);
    font-weight: var(--table-heading-weight);
    text-align: var(--table-heading-text-align);
    padding: 6px;
} 
.comp-financial-block .data-block table thead tr th:last-child{
    border-right: 0px;
}
.comp-financial-block .data-block table tbody tr td{
    border-top: 0px;
    border: 0.25px solid var(--light-border-color);
    border-collapse: collapse;
    font-size: var(--table-cell-font-size);
    text-align: var(--table-cell-text-align);
    font-weight: var(--table-cell-weight);
    padding: var(--table-cell-inner-space);
    height: 35px;
    vertical-align: middle;
    color: var(--table-cell-color);
}

/* .comp-financial-block .data-block table thead tr th{
  font-weight: 700;
  color: #484848;
  padding: 12px 0.75rem;
  border: 0.25px solid rgb(57 109 213 / 75%);

} */

.comp-financial-block .data-block table tbody tr td:first-child {
    text-align: var(--table-label-column-text-align);
    font-size: var(--table-label-column-font-size);
    border: 0.25px solid var(--light-border-color);
    font-weight: var(--table-label-column-font-weight);
    color: var(--table-label-column-color);
    border-left: none;
}
.comp-financial-block .data-block table thead tr th:last-child{
    border-right: 0px;    
}
.comp-financial-block .data-block table tbody tr:last-child td{
    border-bottom: 0px;
}
.comp-financial-block .data-block table tbody tr td:last-child{
    border-right: 0px;
}